import React, { useState, useEffect } from 'react';
import DatePicker from './DatePicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function RangePicker({
    format,
    startDate,
    endDate,
    onRangeChange,
    onDateModifiedChange,
    className = 'flex items-center gap-2',
    color,
    startLabel,
    endLabel,
    errorStartDate,
    errorEndDate,
    setStartDate,
    setEndDate,
}) {
    const { t } = useTranslation();
    // State pour gérer les dates sélectionnées
    const [selectedStartDate, setSelectedStartDate] = useState(
        startDate ? moment(startDate).toDate() : null,
    );
    const [selectedEndDate, setSelectedEndDate] = useState(
        endDate ? moment(endDate).toDate() : null,
    );

    useEffect(() => {
        if (startDate && moment(startDate, format).isValid())
            setSelectedEndDate(moment(startDate, format).toDate());
        if (endDate && moment(endDate, format).isValid())
            setSelectedEndDate(moment(endDate, format).toDate());
    }, []);

    // Effet pour déclencher le callback onRangeChange lorsque les dates sélectionnées changent
    useEffect(() => {
        if (onRangeChange && selectedStartDate && selectedEndDate)
            onRangeChange(selectedStartDate, selectedEndDate);
    }, [selectedStartDate, selectedEndDate]);

    const handleDateChange = (date, type) => {
        const formattedDate = moment(date, format).toDate();

        if (type === 'START') {
            if (selectedEndDate && formattedDate > selectedEndDate) {
                setSelectedEndDate(formattedDate);
                setEndDate(formattedDate);
            }
            setSelectedStartDate(formattedDate);
            setStartDate(formattedDate);
        } else if (type === 'END') {
            if (selectedStartDate && formattedDate < selectedStartDate) {
                setSelectedStartDate(formattedDate);
                setStartDate(formattedDate);
            }
            setSelectedEndDate(formattedDate);
            setEndDate(formattedDate);
        }
        onDateModifiedChange && onDateModifiedChange(date);
    };

    return (
        <div className={className}>
            {/* Premier DatePicker pour la date de début */}
            <DatePicker
                color={color}
                label={startLabel || t('common.transDateStartLabel')}
                selectedDate={startDate}
                onDateChange={(date) => handleDateChange(date, 'START')}
                format={format}
                error={errorStartDate}
            />
            {/* Deuxième DatePicker pour la date de fin */}
            <DatePicker
                color={color}
                label={endLabel || t('common.transDateEndLabel')}
                selectedDate={endDate}
                onDateChange={(date) => handleDateChange(date, 'END')}
                format={format}
                type='endDate'
                minDate={startDate}
                error={errorEndDate}
            />
        </div>
    );
}
