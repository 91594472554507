import React, { useState, useEffect, useRef } from 'react';
import { Table } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { dateFormat } from '../../../modUtils/functions/dateFormat';
import { useDispatch, useSelector } from 'react-redux';
import { get_device_histories_by_equipment_by_group_paginated } from '../../slices/equipmentSlice';
import { useTranslation } from 'react-i18next';
import { eFeatureId, eDeviceStatus } from '../../../../commun_global/enums';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/handlePermissions';
import PermissionDeniedPage from 'ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage';
import './DevicesHistory.scss';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import useScrollHandler from '../../../modUtils/hooks/useScrollHandler';
import PermissionFadeContainer from '../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer';
import i18next from 'i18next';

export default function DevicesHistory({ equipment }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const { groupId, selectedGroup, loadingGroup } = useSelector(
        (state) => state.manager,
    );
    const { equipmentDevicesHistory } = useSelector((state) => state.equipment);
    const [devices, setDevices] = useState([]);
    const defaultOffset = 0;
    const defaultLimit = 25;
    const incrementalLimit = 2;
    const [limit, setLimit] = useState(defaultLimit); // Nombre d'éléments à charger à chaque fois
    const [offset, setOffset] = useState(defaultOffset); // Offset actuel pour la pagination
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(false);
    const [equipmentDevicesHistoryLoader, setEquipmentDevicesHistoryLoader] =
        useState(equipmentDevicesHistory);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_equipment:show_device',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_equipment:show_device',
        groupPermissions,
    );

    const fetchData = async (offset, limit) => {
        setLoading(true);
        if (offset == defaultOffset) {
            setDevices([]);
        }

        try {
            setLoading(true);
            if (offset !== defaultOffset) {
                setIsLoadingMore(true);
            }

            const response = await dispatch(
                get_device_histories_by_equipment_by_group_paginated({
                    groupId: groupId,
                    equipmentId: equipment.id,
                    limit,
                    offset,
                }),
            ).unwrap();
            if (response.results.length === 0) {
                setHasMore(false);
            } else {
                setDevices((prevData) => [...prevData, ...response.results]);
                setOffset(offset + limit);
                let hasMore = response.next !== null ? true : false;
                setHasMoreDataToLoad(hasMore);
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error.status}
                        alertMessage={t('devicesHistory.errorMessage')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (verifyPermission && groupId && equipment.id) {
            fetchData(defaultOffset, defaultLimit);
        }
    }, [groupId, equipment.id, i18next.language]);
    const onScrollEnd = () => {
        if (!loading && hasMore) {
            fetchData(offset, incrementalLimit);
        }
    };

    const { tableRef, handleScroll } = useScrollHandler(onScrollEnd);

    const columns = [
        {
            title: t('table.tabColDate'),
            dataIndex: 'date',
            key: 'date',
            width: '20%',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColDevice'),
            dataIndex: 'feature_id',
            key: 'feature_id',
            align: 'left',
            ellipsis: true,
            bold: true,
            width: '30%',
            render: (text, record) => (
                <span>
                    {t(eFeatureId[text])}{' '}
                    {record?.device_id > 1 && record?.device_id}
                </span>
            ),
        },
        {
            title: t('table.tabColStatus'),
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            bold: true,
            render: (text, record) => (
                <div className='flex-display flex-align-start'>
                    <span
                        className={`codeEllips ${text == 4 ? 'codeOk' : 'codeError'}`}
                    ></span>
                    <span style={{ marginLeft: '10px' }}>
                        {record.code_label}
                        {record.code != 0 && `(${record.code})`}
                    </span>
                </div>
            ),
        },
    ];

    return (
        <div
            ref={tableRef}
            className='devicesLastHistoryTable'
            style={{ overflowY: 'auto', height: '690px' }}
        >
            {!verifyPermission ? (
                <PermissionDeniedPage permission={verifyGroupPermission} />
            ) : (
                <PermissionFadeContainer
                    permission={verifyGroupPermission}
                    opacity={50}
                >
                    <Table
                        columns={columns}
                        dataSource={equipmentDevicesHistoryLoader}
                        scroll={{ y: 690 }}
                        onScroll={handleScroll}
                    />
                </PermissionFadeContainer>
            )}
        </div>
    );
}
