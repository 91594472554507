import { useTranslation } from 'react-i18next';
import {
    Pagination,
    Table,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { useState } from 'react';
import TicketActionsMenu from '../ticketActionsMenu/TicketActionsMenu';
import { ePromotionStatus } from '../../assets/js/ennum';

const TicketsTable = ({
    promo,
    toggleModalTestPromo,
    toggleModalSendTicketMail,
    toggleModalCloseTicket,
}) => {
    const { t } = useTranslation();
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const columns = [
        {
            title: t('modalViewTickets.table.transCreate'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            ellipsis: true,
        },
        {
            title: t('modalViewTickets.table.transCode'),
            dataIndex: 'code',
            key: 'code',
            ellipsis: true,
        },
        {
            title: t('modalViewTickets.table.transStartDate'),
            dataIndex: 'startDate',
            key: 'startDate',
            ellipsis: true,
        },
        {
            title: t('modalViewTickets.table.transEndDate'),
            dataIndex: 'endDate',
            key: 'endDate',
            ellipsis: true,
        },
        {
            title: t('modalViewTickets.table.TransUsed'),
            dataIndex: 'used',
            key: 'used',
            ellipsis: true,
            render: (text, record) => (
                <div>
                    <span>
                        {record.usedCode} / {record.quantiy}
                    </span>
                </div>
            ),
        },
        {
            title: t('modalViewTickets.table.transWhen'),
            dataIndex: 'use',
            key: 'use',
            ellipsis: true,
        },
        {
            title: t('modalViewTickets.table.transStatus'),
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            render: (text, record) => (
                <span className='capitalize'>{t(ePromotionStatus[text])}</span>
            ),
        },
        {
            title: t('modalViewTickets.table.transActions'),
            dataIndex: '',
            key: '',
            render: (text, record) => (
                <TicketActionsMenu
                    record={record}
                    toggleModalTestPromo={toggleModalTestPromo}
                    toggleModalSendTicketMail={toggleModalSendTicketMail}
                    toggleModalCloseTicket={toggleModalCloseTicket}
                    promoTitle={promo.date}
                />
            ),
        },
    ];

    return (
        <div>
            <Table columns={columns} dataSource={promo?.tickets} tableClassName={'min-w-[970px] '} />
            {promo?.tickets?.length > 0 && (
                <div className='flex flex-row justify-end mt-4'>
                    <Pagination
                        defaultCurrent={currentPage}
                        defaultPageSize={limit}
                        showSizeChange={false}
                        total={50}
                    />
                </div>
            )}
        </div>
    );
};

export default TicketsTable;
