import { useTranslation } from 'react-i18next';
import {
    Input,
    Label,
    Textarea,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import RadioInput from '../../../modUtils/components/radio/RadioInput';
import RadioGroup from '../../../modUtils/components/radioGroup/RadioGroup';
import { renderError } from '../../../modUtils/functions/renderError';

const FirstStepperScreen = ({ values, errors, handleChange }) => {
    const { t } = useTranslation();

    return (
        <div className='FirstStepperScreen'>
            <div className='flex-display justify-content-center flex-column align-items-center w-full sm:w-3/6 m-auto'>
                <div className='w-full'>
                    <Input
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transNamePromotion',
                        )}
                        name='promotionName'
                        id='promotionName'
                        value={values.promotionName}
                        onChange={handleChange}
                        className={errors?.promotionName ? 'error-input' : ''}
                        error={renderError(t, errors, 'promotionName')}
                    />
                </div>
                <div className='w-full'>
                    <Textarea
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transDescription',
                        )}
                        name='description'
                        id='description'
                        rows={4}
                        value={values.description}
                        onChange={handleChange}
                        className={'p-2'}
                    />
                </div>
                <div className='w-full checkbox-block mt-4'>
                    <Label
                        className='label'
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transChooseTypePromotion',
                        )}
                    />
                    <RadioGroup
                        name='typePromo'
                        defaultValue={values.typePromo}
                        onChange={handleChange}
                        error={errors?.typePromo}
                    >
                        <RadioInput
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transGenerateCodePromo',
                            )}
                            description={t(
                                'createPromotionModal.stepperCreatePromotion.transGenerateCodePromoDescription',
                            )}
                            value={0}
                        />
                        <RadioInput
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transAppliMobCodePromo',
                            )}
                            description={t(
                                'createPromotionModal.stepperCreatePromotion.transAppliMobCodePromoDescription',
                            )}
                            value={1}
                        />
                    </RadioGroup>
                </div>
            </div>
        </div>
    );
};

export default FirstStepperScreen;
