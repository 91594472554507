import {
    Select,
    Input,
    Label,
    Table,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import DayCard from '../dayCard/DayCard';
import { useSelector } from 'react-redux';
import { eEquipmentType } from '../../../../commun_global/enums';
import RangePicker from '../../../modUtils/components/componentsLibrary/datePicker/RangePicker';
import Button from '../../../modUtils/components/buttons/Button';
import { CgAddR } from 'react-icons/cg';
import { IoClose } from 'react-icons/io5';
import RepeaterTime from '../repeaterTime/RepeaterTime';
import { columns, data } from './constants';
import CheckboxInput from '../../../modUtils/components/componentsLibrary/checkbox/Checkbox';
import moment from 'moment';
import TooltipLabel from '../../../modUtils/components/tooltipLabel/TooltipLabel';
import { renderError } from '../../../modUtils/functions/renderError';

const SecondStepperScreen = ({
    values,
    handleChange,
    days,
    setFieldValue,
    errors,
}) => {
    const { t } = useTranslation();
    const [listDays, setListDays] = useState([]);
    const { sites } = useSelector((state) => state.manager);
    const { equipmentTypeGroup } = useSelector((state) => state.equipment);
    const [addNewContraint, setAddNewContraint] = useState(false);
    const [allDay, setAllDay] = useState(false);
    const [specificHours, setSpecificHours] = useState(false);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const style = {
        td: {
            padding: 0,
        },
    };

    const selectDay = (value) => {
        listDays.includes(value)
            ? setListDays(listDays.filter((day) => day !== value))
            : setListDays([...listDays, value]);
        handleChange({ target: { name: 'days', value: listDays } });
    }; // Fonction pour gérer le changement de plage de dates

    const handleRangeChange = (start, end) => {
        // Fonction pour gérer le changement de plage de dates        
        if (start) {
            setStartDate(start)
            setFieldValue('startDateOfferObtainPromo', moment(start).unix());
        }
        if (end) {
            setEndDate(end)
            setFieldValue('endDateOfferObtainPromo', moment(end).unix());
        }
    };

    const onSelect = (e, name) => {
        const currentValues = values[name] || [];
        setFieldValue(name, [...currentValues, e]);
    };

    const onDeselect = (e, name) => {
        const newSitesData = values[name].filter((site) => site !== e);
        setFieldValue(name, newSitesData);
    };

    return (
        <div className='SecondStepperScreen'>
            <div className='flex-display justify-content-center'>
                <div className='w-full flex-display flex-column align-items-center'>
                    <div className='flex-display align-items-baseline justify-content-center gap-1'>
                        <CheckboxInput
                            errors={errors}
                            onChange={(e) =>
                                setFieldValue(
                                    'enableObtainPromo',
                                    e.target.checked,
                                )
                            }
                            name={'enableObtainPromo'}
                            value={'enableObtainPromo'}
                            checked={values.enableObtainPromo}
                            id={'enableObtainPromo'}
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transEnableDisablePrinting',
                            )}
                        />
                        <TooltipLabel
                            tooltipMessage={t(
                                'createPromotionModal.stepperCreatePromotion.transEnableDisablePrintingTooltipMessage',
                            )}
                        />
                    </div>
                    <div className='flex-col border border-[#4D4D4D42] border-solid py-2.5 px-6 sm:px-12 rounded-[5px] sm:w-max w-full my-4 mx-auto gap-4'>
                        <TooltipLabel
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transOfferValidity',
                            )}
                            tooltipMessage={t(
                                'createPromotionModal.stepperCreatePromotion.transEnableDisablePrintingTooltipMessage',
                            )}
                        />
                        <RangePicker
                            format={'DD/MM/YYYY'}
                            onRangeChange={handleRangeChange}
                            className={'flex items-center gap-2'}
                            color={'neutral'}
                            errorStartDate={renderError(
                                t,
                                errors,
                                'startDateOfferObtainPromo',
                            )}
                            errorEndDate={renderError(
                                t,
                                errors,
                                'endDateOfferObtainPromo',
                            )}
                            startLabel={t(
                                'createPromotionModal.stepperCreatePromotion.transFrom',
                            )}
                            endLabel={t(
                                'createPromotionModal.stepperCreatePromotion.transTo',
                            )}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                    <Row className='gap-2 w-full justify-between'>
                        <Col
                            lg={5}
                            md={24}
                            sm={24}
                            sx={24}
                            className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                        >
                            <div className='w-full checkbox-block'>
                                <TooltipLabel
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transOffer',
                                    )}
                                    tooltipMessage={t(
                                        'createPromotionModal.stepperCreatePromotion.transOfferTooltipMessage',
                                    )}
                                />
                                <Input
                                    type={'number'}
                                    min={0}
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transMaximumUse',
                                    )}
                                    onChange={handleChange}
                                    placeholder={`0 ${t(
                                        'createPromotionModal.stepperCreatePromotion.transTimes',
                                    )}`}
                                    name='maximumPrintingObtainPromo'
                                    value={values.maximumPrintingObtainPromo}
                                    id='maximumPrintingObtainPromo'
                                    width={'100%'}
                                    className={`w-full`}
                                    classnames={'h-12 w-full'}
                                    inputMode='numeric'
                                    pattern='\d*'
                                    error={renderError(
                                        t,
                                        errors,
                                        'maximumPrintingObtainPromo',
                                    )}
                                />
                            </div>
                        </Col>
                        <Col
                            lg={5}
                            md={6}
                            sm={24}
                            sx={24}
                            className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                        >
                            <TooltipLabel
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transWhere',
                                )}
                                tooltipMessage={t(
                                    'createPromotionModal.stepperCreatePromotion.transWhereToolitipMessage',
                                )}
                            />
                            <div className='flex flex-col gap-4'>
                                <div>
                                    <Select
                                        multiple
                                        error={renderError(
                                            t,
                                            errors,
                                            'sitesObtainPromo',
                                        )}
                                        onSelect={(value) =>
                                            onSelect(value, 'sitesObtainPromo')
                                        }
                                        onDeselect={(value) =>
                                            onDeselect(
                                                value,
                                                'sitesObtainPromo',
                                            )
                                        }
                                        style={{
                                            selectLabel: { color: '#25292D' },
                                        }}
                                        selectLabel={t(
                                            'createPromotionModal.stepperCreatePromotion.transSite',
                                        )}
                                        containerWidth={'full'}
                                        dropdownMatchSelectWidth
                                        msgDisplayedWhenNoOptions={t(
                                            'createPromotionModal.stepperCreatePromotion.transNoData',
                                        )}
                                        defaultValue={values.sitesObtainPromo}
                                        block
                                        name='sitesObtainPromo'
                                        placeholder={t(
                                            'createPromotionModal.stepperCreatePromotion.transSelect',
                                        )}
                                        options={sites?.map((site) => ({
                                            value: site.id,
                                            label: site.name,
                                        }))}
                                        selectedMessage={
                                            values.sitesObtainPromo?.length
                                                ? `${values.sitesObtainPromo?.length} ${t(
                                                      'createPromotionModal.stepperCreatePromotion.transSelectedSites',
                                                  )}`
                                                : t(
                                                      'createPromotionModal.stepperCreatePromotion.transNoSitesSelected',
                                                  )
                                        }
                                    />
                                </div>
                                <div>
                                    <Select
                                        multiple
                                        error={renderError(
                                            t,
                                            errors,
                                            'equipementsTypeObtainPromo',
                                        )}
                                        onSelect={(value) =>
                                            onSelect(
                                                value,
                                                'equipementsTypeObtainPromo',
                                            )
                                        }
                                        onDeselect={(value) =>
                                            onDeselect(
                                                value,
                                                'equipementsTypeObtainPromo',
                                            )
                                        }
                                        style={{
                                            selectLabel: { color: '#25292D' },
                                        }}
                                        selectLabel={t(
                                            'createPromotionModal.stepperCreatePromotion.transEquipmentType',
                                        )}
                                        containerWidth={'full'}
                                        dropdownMatchSelectWidth
                                        msgDisplayedWhenNoOptions={t(
                                            'createPromotionModal.stepperCreatePromotion.transNoData',
                                        )}
                                        defaultValue={
                                            values.equipementsTypeObtainPromo
                                        }
                                        block
                                        name='equipementsTypeObtainPromo'
                                        placeholder={t(
                                            'createPromotionModal.stepperCreatePromotion.transSelect',
                                        )}
                                        options={equipmentTypeGroup?.map(
                                            (equipmentType) => ({
                                                label: t(
                                                    eEquipmentType[
                                                        equipmentType.type
                                                    ],
                                                ),
                                                value: equipmentType.type,
                                            }),
                                        )}
                                        selectedMessage={
                                            values.equipementsTypeObtainPromo
                                                ?.length
                                                ? `${values.equipementsTypeObtainPromo?.length} ${t(
                                                      'createPromotionModal.stepperCreatePromotion.transSelectedEquipmentType',
                                                  )}`
                                                : t(
                                                      'createPromotionModal.stepperCreatePromotion.transNoEquipmentTypeSelected',
                                                  )
                                        }
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col
                            lg={8}
                            md={12}
                            sm={24}
                            sx={24}
                            className='border border-[#4D4D4D42] border-solid rounded-[5px]'
                        >
                            {!addNewContraint ? (
                                <div className='p-2'>
                                    <TooltipLabel
                                        label={t(
                                            'createPromotionModal.stepperCreatePromotion.transWhen',
                                        )}
                                        tooltipMessage={t(
                                            'createPromotionModal.stepperCreatePromotion.transWhenToolitipMessage',
                                        )}
                                    />
                                    <Table
                                        style={style}
                                        columns={columns(t)}
                                        dataSource={data}
                                    />
                                    <div className='flex justify-center mt-4'>
                                        <Button
                                            color={'dark'}
                                            onClick={() =>
                                                setAddNewContraint(true)
                                            }
                                            className={
                                                'font-bold text-xs w-auto shadow-none flex-display align-items-center gap-2'
                                            }
                                        >
                                            <CgAddR />{' '}
                                            {t(
                                                'createPromotionModal.stepperCreatePromotion.transAddContraint',
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div className='bg-white rounded'>
                                    <div className='flex justify-end p-2'>
                                        <IoClose
                                            onClick={() =>
                                                setAddNewContraint(false)
                                            }
                                            color='#1B8ADC'
                                            fontSize={20}
                                            className='cursor-pointer'
                                        />
                                    </div>
                                    <div className='p-5'>
                                        <div className='flex-display align-items-baseline'>
                                            <CheckboxInput
                                                id='everyDay'
                                                name='days'
                                                value={'everyDay'}
                                                checked={allDay}
                                                onChange={(e) =>
                                                    setAllDay(e.target.checked)
                                                }
                                                label={t(
                                                    'createPromotionModal.stepperCreatePromotion.transEveryDay',
                                                )}
                                            />
                                        </div>
                                        <div className='flex-display align-items-center justify-content-center gap-2 mt-4'>
                                            {days &&
                                                days.length &&
                                                days.map((day) => (
                                                    <DayCard
                                                        day={day}
                                                        selectDay={selectDay}
                                                        selected={
                                                            listDays.includes(
                                                                day,
                                                            ) || allDay
                                                        }
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                    <div className='p-5 pt-1'>
                                        <div className='flex-display align-items-baseline'>
                                            <CheckboxInput
                                                id='specificHours'
                                                name='hours'
                                                value={'specificHours'}
                                                checked={specificHours}
                                                onChange={(e) =>
                                                    setSpecificHours(
                                                        e.target.checked,
                                                    )
                                                }
                                                label={t(
                                                    'createPromotionModal.stepperCreatePromotion.transSpecificHours',
                                                )}
                                            />
                                        </div>
                                        <RepeaterTime
                                            startTimeLabel={t(
                                                'createPromotionModal.stepperCreatePromotion.transFromTime',
                                            )}
                                            endTimeLabel={t(
                                                'createPromotionModal.stepperCreatePromotion.transToTime',
                                            )}
                                            disabled={!specificHours}
                                        />
                                    </div>
                                </div>
                            )}
                        </Col>
                        <Col
                            lg={5}
                            md={6}
                            sm={24}
                            sx={24}
                            className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                        >
                            <TooltipLabel
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transWhy',
                                )}
                                tooltipMessage={t(
                                    'createPromotionModal.stepperCreatePromotion.transWhyToolitipMessage',
                                )}
                            />
                            <p className='text-sm'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transWhyDescription',
                                ) + localStorage.getItem('currencySymbol')}
                            </p>
                            <div className='flex items-center gap-2'>
                                <Input
                                    label={
                                        t(
                                            'createPromotionModal.stepperCreatePromotion.transMin',
                                        ) +
                                        localStorage.getItem('currencySymbol')
                                    }
                                    onChange={handleChange}
                                    name='minObtainPromo'
                                    value={values.minObtainPromo}
                                    id='minObtainPromo'
                                    type='number'
                                    min={0}
                                    step='1'
                                    placeholder='0'
                                    error={renderError(
                                        t,
                                        errors,
                                        'minObtainPromo',
                                    )}
                                />
                                <Input
                                    onChange={handleChange}
                                    min={0}
                                    label={
                                        t(
                                            'createPromotionModal.stepperCreatePromotion.transMax',
                                        ) +
                                        localStorage.getItem('currencySymbol')
                                    }
                                    name='maxObtainPromo'
                                    value={values.maxObtainPromo}
                                    id='maxObtainPromo'
                                    type='number'
                                    step='1'
                                    placeholder='10'
                                    error={renderError(
                                        t,
                                        errors,
                                        'maxObtainPromo',
                                    )}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default SecondStepperScreen;
