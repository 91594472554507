import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const RadioGroup = ({ name, defaultValue, onChange, error, children }) => {
    const { t } = useTranslation();

    return (
        <div>
            <Radio.Group
                name={name}
                defaultValue={defaultValue}
                onChange={onChange}
            >
                {children}
                {error && (
                    <p className='error text-error-color'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.validationForm.transRequiredField',
                        )}
                    </p>
                )}
            </Radio.Group>
        </div>
    );
};

export default RadioGroup;
