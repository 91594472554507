import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { eEquipmentType } from '../../../commun_global/enums';
import {
    get_constraint_financial_list,
    delete_constraint_financial_detail,
} from '../slices/constraintFinancialSlice';
import { ReactComponent as DeleteIcon } from '../../modEntity/assets/images/trash-can.svg';
import { getPeriodLabel } from '../functions/handleDaysOfWeek';
import AddFinancialConstraintModal from './AddFinancialConstraintModal';
import { update_configuration_detail_by_profil } from '../slices/configurationSlice';
import { amountFormat } from '../../modUtils/functions/numberFormat';
import ButtonAdd from '../../modUtils/components/buttons/ButtonAdd';
import {
    Spinner,
    Table,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import CheckboxCRM from './CheckboxCRM';

export default function FinancialConstraints({
    selectedProfilId,
    selectedConfigId,
    configuration,
    getConfigurationDetail,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const { constraintsFinancial } = useSelector(
        (state) => state.profil.constraintFinancial,
    );
    const [
        isAddFinancialConstraintModalVisible,
        setIsAddFinancialConstraintModalVisible,
    ] = useState(false);

    // récupérer la liste des contraintes finanicères
    const getConstraintFinancial = async (limit, offset) => {
        await dispatch(
            get_constraint_financial_list({
                groupId,
                configurationId: selectedConfigId,
            }),
        ).unwrap();
    };

    useEffect(() => {
        groupId && selectedConfigId && getConstraintFinancial();
    }, [groupId, selectedConfigId]);

    // supprimer une contrainte financière
    const deleteConstraintFinancial = async (constraintId) => {
        setLoading(true);
        setLoading(true);
        await dispatch(
            delete_constraint_financial_detail({
                groupId,
                configurationId: selectedConfigId,
                constraintId,
            }),
        ).unwrap();
        setLoading(false);
    };

    const columns = [
        {
            title: 'financialConstraints.equipmentType',
            dataIndex: 'equipment_type',
            key: 'equipment_type',
            width: '40%',
            align: 'left',
            render: (text, record) => (
                <div className='text-center'>
                    {text === null ? 'Tous' : t(eEquipmentType[text])}
                </div>
            ),
        },
        {
            title: 'financialConstraints.periodType',
            dataIndex: 'period_type',
            key: 'period_type',
            width: '20%',
            render: (text, record) => getPeriodLabel(text),
            // TODO CH enum e_period_type
        },
        {
            title: 'financialConstraints.limitIn',
            dataIndex: 'amount_max',
            key: 'amount_max',
            width: '20%',
            render: (text, record) => (text ? amountFormat(text) : '-'),
        },
        {
            title: 'financialConstraints.limitInUse',
            dataIndex: 'usage_max',
            key: 'usage_max',
            width: '20%',
            render: (text, record) => <div className='text-center'>{text}</div>,
        },
        ...(configuration.is_future
            ? [
                  {
                      title: '',
                      dataIndex: 'id',
                      key: 'id',
                      width: '10%',
                      render: (text) => (
                          <div
                              className='w-[15px] m-auto cursor-pointer'
                              onClick={() => deleteConstraintFinancial(text)}
                          >
                              <DeleteIcon />
                          </div>
                      ),
                  },
              ]
            : []),
    ];

    // appelé au changement de checkbox pour activer ou désactiver les contraintes financières
    const handleOnChangeCheckbox = async () => {
        try {
            const response = await dispatch(
                update_configuration_detail_by_profil({
                    groupId,
                    profilId: selectedProfilId,
                    configurationId: selectedConfigId,
                    constraintsFinancialIsActive:
                        !configuration?.constraintfinancial_is_active,
                }),
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de la (dé)activation des contraintes financières !`}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        }

        getConfigurationDetail(selectedProfilId, selectedConfigId);
    };

    return (
        <>
            {configuration?.is_future && (
                <CheckboxCRM
                    checked={configuration.constraintfinancial_is_active}
                    onChange={handleOnChangeCheckbox}
                    label='financialConstraints.enableFinancialConstraints'
                />
            )}

            {configuration.constraintfinancial_is_active && (
                <div className='flex flex-col justify-center items-center'>
                    <span className='font-black text-sm my-6'>
                        {t('financialConstraints.financialConstraints')}
                    </span>

                    {configuration?.is_future == true && (
                        <div className='justify-center items-center'>
                            <ButtonAdd
                                label={t(
                                    'financialConstraints.addFinancialConstraint',
                                )}
                                className='mb-4'
                                onClick={() =>
                                    setIsAddFinancialConstraintModalVisible(
                                        true,
                                    )
                                }
                            />
                            {isAddFinancialConstraintModalVisible && (
                                <AddFinancialConstraintModal
                                    isModalVisible={
                                        isAddFinancialConstraintModalVisible
                                    }
                                    setIsModalVisible={
                                        setIsAddFinancialConstraintModalVisible
                                    }
                                    selectedConfigId={selectedConfigId}
                                    setLoading={setLoading}
                                />
                            )}
                        </div>
                    )}

                    {loading ? (
                        <Spinner />
                    ) : (
                        <Table
                            columns={columns}
                            dataSource={constraintsFinancial}
                        />
                    )}
                </div>
            )}
        </>
    );
}
