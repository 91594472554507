// import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { IoMdCopy } from 'react-icons/io';
import { HiOutlinePencil } from 'react-icons/hi';
import { FaRegChartBar } from 'react-icons/fa';
import './PromoCard.scss';
import { ReactComponent as FileIcon } from '../../assets/images/fileIcon.svg';
import { PiSealPercentFill } from 'react-icons/pi';
import Button from '../../../modUtils/components/buttons/Button';
import { FiEye } from 'react-icons/fi';
import { amountFormatWithCurrencySymbol } from '../../../modUtils/functions/numberFormat';
import moment from 'moment';
import { dateFormat } from '../../../modUtils/functions/dateFormat';
import { Card } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { useSelector } from 'react-redux';
import { eEquipmentType } from '../../../../commun_global/enums';

const statusColors = {
    0: '#00d22a',
    1: '#338EF5',
};

const PromoCard = ({
    promo,
    toggleModalTickets,
    toogleCreatePromotionModal,
}) => {
    const { t } = useTranslation();
    const { sites } = useSelector((state) => state.manager);
    const { equipmentTypeGroup } = useSelector((state) => state.equipment);

    const getStatusIcon = () => {
        if (
            moment(moment().unix()).isAfter(
                moment(moment(promo.criteria_usage.date_end)),
            )
        )
            return <PiSealPercentFill fontSize={20} color={'#afaaab'} />;
        if (promo.promotion_type)
            return <PiSealPercentFill fontSize={20} color={statusColors[1]} />;
        return <FileIcon />;
    };

    const getBackgroundColor = () =>
        moment(moment().unix()).isAfter(moment(promo.criteria_usage.date_end))
            ? '#EFEFEF'
            : '#fff';

    const getFooterColor = () =>
        moment(moment().unix()).isAfter(moment(promo.criteria_usage.date_end))
            ? '#fff'
            : '#2D62ED';

    return (
        <Card
            className='promoCard rounded h-full p-0 shadow-none'
            style={{
                card: {
                    backgroundColor: getBackgroundColor(),
                    border: 'solid 1.5px #4D4D4D42',
                },
                cardBody: {
                    padding: 0,
                    justifyContent: 'space-between',
                    height: '100%',
                },
            }}
        >
            <div>
                <div className='card-header flex-display align-items-center flex-col sm:flex-row justify-content-between gap-2 pb-0 pt-3 px-3 mb-4'>
                    <div className='flex gap-2 w-full sm:w-auto'>
                        {getStatusIcon()}
                        <div className='flex flex-row sm:flex-col justify-between w-full sm:w-auto gap-2'>
                            <h1
                                className='title-card text-left mt-0 mb-0 leading-5 font-bold text-xl tracking-wide uppercase'
                                style={{
                                    color: moment(moment().unix()).isAfter(
                                        moment(promo.criteria_usage.date_end),
                                    )
                                        ? '#afaaab'
                                        : statusColors[promo.promotion_type],
                                }}
                            >
                                {promo.name}
                            </h1>
                            <div
                                className={`py-2 px-3.5 text-xs font-semibold rounded-sm w-max border-solid border text-[${
                                    moment(moment().unix()).isAfter(
                                        moment(promo.criteria_usage.date_end),
                                    )
                                        ? '#afaaab'
                                        : statusColors[promo.promotion_type]
                                }] border-[${
                                    moment(moment().unix()).isAfter(
                                        moment(promo.criteria_usage.date_end),
                                    )
                                        ? '#afaaab'
                                        : statusColors[promo.promotion_type]
                                }]`}
                                style={{
                                    borderColor: moment(
                                        moment().unix(),
                                    ).isAfter(
                                        moment(promo.criteria_usage.date_end),
                                    )
                                        ? '#afaaab'
                                        : statusColors[promo.promotion_type],
                                    color: moment(
                                        moment().unix(),
                                    ).isAfter(
                                        moment(promo.criteria_usage.date_end),
                                    )
                                        ? '#afaaab'
                                        : statusColors[promo.promotion_type],
                                }}
                            >
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transFrom',
                                )}{' '}
                                {dateFormat(
                                    promo.criteria_usage.date_start,
                                    'DD/MM/YYYY',
                                )}{' '}
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transTo',
                                )}{' '}
                                {dateFormat(
                                    promo.criteria_usage.date_end,
                                    'DD/MM/YYYY',
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row sm:flex-col justify-between w-full sm:w-auto gap-2'>
                        <div className='flex-display align-items-center gap-2 justify-end'>
                            <HiOutlinePencil
                                className='cursor-pointer'
                                fontSize={18}
                                onClick={() =>
                                    toogleCreatePromotionModal(promo.id)
                                }
                            />
                            <IoMdCopy
                                className='cursor-pointer'
                                fontSize={18}
                            />
                            <p className='text-semibold text-xs leading-4 mb-1'>
                                {t('card.transIdentification')}: {promo.id}
                            </p>
                        </div>
                        <Button
                            color='primary'
                            className='ml-3 flex gap-1 items-center text-xs font-bold'
                            onClick={() => toggleModalTickets(promo)}
                        >
                            <FiEye />
                            {t('card.transShowButton')}
                        </Button>
                    </div>
                </div>
                <div
                    className='card-body py-2.5 flex flex-col sm:flex-row justify-center m-auto'
                    style={{ width: 'calc(100% - 1.5rem)' }}
                >
                    <div className='pr-3 pl-7 text-left py-2 w-1/2 sm:py-0 border-t-0 border-l-0 border-b sm:border-b-0 border-solid border-r-0 sm:border-r border-[#BBBBBB] w-full sm:w-1/2'>
                        <p className='font-bold mb-0 section-title text-sm'>
                            {t('card.transDescription')}
                        </p>
                        <p className='font-medium mb-0 text-xs line-clamp-4'>
                            {promo.description}
                        </p>
                    </div>
                    <div className='section px-3 py-2 w-1/2 text-left sm:py-0'>
                        <p className='font-bold mb-0 section-title text-sm'>
                            {t('card.transPropertyUse')}
                        </p>
                        {promo.discount && (
                            <p className='font-medium mb-0 text-xs'>
                                {t('card.transDiscountOf')} {promo.discount}€
                            </p>
                        )}
                        {promo.hours && (
                            <p className='font-medium mb-0 section-subtitle text-xs leading-5'>
                                {t('card.transHoursOfUse')}: {promo.hours}
                            </p>
                        )}
                        {promo.criteria_usage.site_ids && (
                            <p className='font-medium mb-0 section-subtitle text-xs leading-5'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transSite',
                                )}
                                :{' '}
                                {promo.criteria_usage.site_ids
                                    .map((siteId) => {
                                        const site = sites.find(
                                            (site) => site.id === siteId,
                                        );
                                        return site ? site.name : null;
                                    })
                                    .filter((name) => name !== null) // Remove any null values in case a site ID doesn't match
                                    .join(', ')}
                            </p>
                        )}
                        {promo.criteria_usage.equipment_types && (
                            <p className='font-medium mb-0 section-subtitle text-xs leading-5'>
                                {t('card.transEquipmentType')}{' '}
                                {promo.criteria_usage.equipment_types
                                    .map((equipmentId) => {
                                        const equipment =
                                            equipmentTypeGroup.find(
                                                (equipment) =>
                                                    equipment.type ===
                                                    equipmentId,
                                            );
                                        return equipment
                                            ? t(eEquipmentType[equipment.type])
                                            : null;
                                    })
                                    .filter((name) => name !== null) // Remove any null values in case a site ID doesn't match
                                    .join(', ')}
                            </p>
                        )}{' '}
                        {promo.criteria_usage.amount_min &&
                        promo.criteria_usage.amount_max ? (
                            <p className='font-medium mb-0 text-xs leading-3'>
                                {t('card.transMinMaxAmount')}:{' '}
                                {t('card.transBetween')}{' '}
                                {amountFormatWithCurrencySymbol(
                                    promo.criteria_usage.amount_min || 0,
                                )}{' '}
                                {t('card.transAnd')}{' '}
                                {amountFormatWithCurrencySymbol(
                                    promo.criteria_usage.amount_max || 0,
                                )}
                            </p>
                        ) : null}
                    </div>
                </div>
            </div>
            <div
                className='card-footer p-2.5 rounded-b flex-display align-items-center justify-content-around'
                style={{
                    backgroundColor: moment(moment().unix()).isAfter(
                        moment(promo.criteria_usage.date_end),
                    )
                        ? '#AFAAAB'
                        : '#F1F4FF',
                }}
            >
                {[
                    {
                        label: t('card.transDelivredTicket'),
                        value:
                            promo.criteria_usage.support_distribute_count || 0,
                    },
                    {
                        label: t('card.transTicketClose'),
                        value: promo.count_usage_max || 0,
                    },
                    {
                        label: t('card.transTransformationRate'),
                        value: `${promo.transformationRate || 0}%`,
                    },
                ].map(({ label, value }) => (
                    <div
                        key={label}
                        className='text-center text-xs font-bold leading-3'
                    >
                        <p className='mb-1' style={{ color: getFooterColor() }}>
                            {value}
                        </p>{' '}
                        {label}
                    </div>
                ))}
                <div className='text-center flex-display flex-column align-items-center text-xs font-bold leading-3 gap-1'>
                    <FaRegChartBar fontSize={18} color={getFooterColor()} />
                    {t('card.transStaticsCode')}
                </div>
            </div>
        </Card>
    );
};

export default PromoCard;
