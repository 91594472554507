import { useTranslation } from 'react-i18next';
import {
    Input,
    Modal,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import RangePicker from '../../../modUtils/components/componentsLibrary/datePicker/RangePicker';
import { Formik } from 'formik';
import Button from '../../../modUtils/components/buttons/Button';
import moment from 'moment';

const ModalCloseTicketsGroup = ({
    modalCloseTicketsGroup,
    toggleModalCloseTicketsGroup,
}) => {
    const { t } = useTranslation();

    const handleSubmit = (values, resetForm) => {
        console.log('values', values);
        toggleModalCloseTicketsGroup();
    };

    // Fonction pour gérer le changement de plage de dates
    const handleRangeChange = (start, end, setValues, values) => {
        setValues({ ...values, startDate: start, endDate: end });
    };
    return (
        <Modal
            isOpen={modalCloseTicketsGroup}
            onClose={toggleModalCloseTicketsGroup}
            title={t('modalViewTickets.modalCloseTicketsGroup.transTitle')}
            style={{
                modalTitle: {
                    color: '#25292D',
                    fontSize: '20px',
                    lineHeight: '23px',
                },
            }}
        >
            <Formik
                initialValues={{
                    startDate: null,
                    endDate: null,
                    quantity: null,
                }}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values, resetForm);
                }}
            >
                {({ values, resetForm, setValues, handleChange }) => (
                    <div className='mt-8 w-full sm:w-max mx-auto'>
                        <p className='font-semibold text-sm'>
                            {t(
                                'modalViewTickets.modalCloseTicketsGroup.transDate',
                            )}
                        </p>
                        <RangePicker
                            format={'DD/MM/YYYY'}
                            color={'neutral'}
                            onRangeChange={(start, end) =>
                                handleRangeChange(start, end, setValues, values)
                            }
                            startDate={
                                moment(values.startDate, 'DD/MM/YYYY').isValid()
                                    ? moment(
                                          values.startDate,
                                          'DD/MM/YYYY',
                                      ).toDate()
                                    : null
                            }
                            endDate={
                                moment(values.endDate, 'DD/MM/YYYY').isValid()
                                    ? moment(
                                          values.endDate,
                                          'DD/MM/YYYY',
                                      ).toDate()
                                    : null
                            }
                        />
                        <Input
                            className={'mt-8 w-48'}
                            placeholder={'100'}
                            value={values.quantity}
                            name={'quantity'}
                            type={'number'}
                            min={0}
                            label={t(
                                'modalViewTickets.modalCloseTicketsGroup.transClosedQuantity',
                            )}
                            onChange={handleChange}
                        />
                        <div className='flex justify-center mt-8'>
                            <Button
                                onClick={() => handleSubmit(values, resetForm)}
                                color={'success'}
                            >
                                {t('modalViewTickets.transCloseCode')}
                            </Button>
                        </div>
                    </div>
                )}
            </Formik>
        </Modal>
    );
};

export default ModalCloseTicketsGroup;
