import React, { useState, useEffect } from 'react';
import ProfilEntities from './ProfilEntities';
import { useTranslation } from 'react-i18next';
import ServiceConstraints from './ServiceConstraints';
import ConfigurationDetails from './ConfigurationDetails';
import FinancialConstraints from './FinancialConstraints';
import ScheduleAndPeriodConstraints from './ScheduleAndPeriodConstraints';
import CardOutlineIcon from '../../modUtils/components/card/CardOutlineIcon';
import { ReactComponent as InfosIcon } from '../../modEntity/assets/images/infos.svg';
import { ReactComponent as FinancialIcon } from '../../modEntity/assets/images/euro.svg';
import { ReactComponent as ServiceIcon } from '../../modEntity/assets/images/pointer.svg';
import { ReactComponent as SchedulesIcon } from '../../modEntity/assets/images/horloge.svg';
import { ReactComponent as EntitiesIcon } from '../../modEntity/assets/images/users-three.svg';
import { ReactComponent as InfosIconActive } from '../../modEntity/assets/images/infos-bg-white.svg';
import { ReactComponent as FinancialIconActive } from '../../modEntity/assets/images/euro-bg-white.svg';
import { ReactComponent as ServiceIconActive } from '../../modEntity/assets/images/pointer-bg-white.svg';
import { ReactComponent as SchedulesIconActive } from '../../modEntity/assets/images/horloge-bg-white.svg';
import { ReactComponent as EntitiesIconActive } from '../../modEntity/assets/images/users-three-bg-white.svg';

// TODO CH cette partie n'est pas encore terminée dans le back-end
// import DiscountsDetails from "./DiscountsDetails";
// import { ReactComponent as DiscountIcon } from "../../modEntity/assets/images/pourcentage.svg";
// import { ReactComponent as DiscountIconActive } from "../../modEntity/assets/images/pourcentage-bg-white.svg";

export default function ConfigurationInfoContainer({
    profil,
    selectedProfilId,
    configuration,
    selectedConfigId,
    getConfigurationDetail,
}) {
    const { t } = useTranslation();
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);

    useEffect(() => {
        setActiveButtonIndex(0); //garder Détails ouvert par défaut
    }, [selectedConfigId, selectedProfilId]);

    const renderContent = () => {
        switch (activeButtonIndex) {
            case 0:
                return (
                    <ConfigurationDetails
                        selectedProfilId={selectedProfilId}
                        selectedConfigId={selectedConfigId}
                        getConfigurationDetail={getConfigurationDetail}
                    />
                );
            case 1:
                return (
                    <ScheduleAndPeriodConstraints
                        selectedProfilId={selectedProfilId}
                        selectedConfigId={selectedConfigId}
                        configuration={configuration}
                    />
                );
            case 2:
                return (
                    <FinancialConstraints
                        selectedProfilId={selectedProfilId}
                        selectedConfigId={selectedConfigId}
                        configuration={configuration}
                        getConfigurationDetail={getConfigurationDetail}
                    />
                );
            case 3:
                return (
                    <ServiceConstraints
                        selectedProfilId={selectedProfilId}
                        selectedConfigId={selectedConfigId}
                        configuration={configuration}
                        getConfigurationDetail={getConfigurationDetail}
                    />
                );
            case 5:
                return <ProfilEntities selectedProfilId={selectedProfilId} />;
            default:
                return (
                    <ConfigurationDetails
                        selectedProfilId={selectedProfilId}
                        selectedConfigId={selectedConfigId}
                        getConfigurationDetail={getConfigurationDetail}
                    />
                );
        }
    };

    const handleButtonActivation = (buttonIndex) => {
        setActiveButtonIndex(buttonIndex);
    };

    return (
        <div className='py-8'>
            <div className='flex justify-center'>
                <div
                    className={`grid grid-row min-[1200px]:grid-cols-3 min-[1400px]:grid-cols-4 gap-2 mx-6 justify-center place-items-center min-[1750px]:mx-28`}
                >
                    <div className='col-span-6 min-[1200px]:col-span-3 min-[1400px]:col-span-2 min-[1600px]:col-span-1'>
                        <CardOutlineIcon
                            active={activeButtonIndex === 0}
                            color='#00D9A6'
                            label={t('configurationInfoContainer.details')}
                            iconDefault={<InfosIcon />}
                            iconIsActive={<InfosIconActive />}
                            onClick={() => handleButtonActivation(0)}
                        />
                    </div>

                    <div className='col-span-6 min-[1200px]:col-span-3 min-[1400px]:col-span-2 min-[1600px]:col-span-1'>
                        <CardOutlineIcon
                            active={activeButtonIndex === 1}
                            color='#F53C56'
                            label={t('configurationInfoContainer.schedules')}
                            iconDefault={<SchedulesIcon />}
                            iconIsActive={<SchedulesIconActive />}
                            onClick={() => handleButtonActivation(1)}
                        />
                    </div>

                    <div className='col-span-6 min-[1200px]:col-span-3 min-[1400px]:col-span-2 min-[1600px]:col-span-1'>
                        <CardOutlineIcon
                            active={activeButtonIndex === 2}
                            color='#0088FF'
                            label={t('configurationInfoContainer.financial')}
                            iconDefault={<FinancialIcon />}
                            iconIsActive={<FinancialIconActive />}
                            onClick={() => handleButtonActivation(2)}
                        />
                    </div>

                    <div className='col-span-6 min-[1200px]:col-span-3 min-[1400px]:col-span-2 min-[1600px]:col-span-1'>
                        <CardOutlineIcon
                            active={activeButtonIndex === 3}
                            color='#008CB3'
                            label='Service'
                            iconDefault={<ServiceIcon />}
                            iconIsActive={<ServiceIconActive />}
                            onClick={() => handleButtonActivation(3)}
                        />
                    </div>

                    <div
                        className={`${
                            profil?.service_type !== 0 ? 'xl:start-col-5' : ''
                        } col-span-6 min-[1200px]:col-span-3 min-[1400px]:col-span-2 min-[1600px]:col-span-1`}
                    >
                        <CardOutlineIcon
                            active={activeButtonIndex === 5}
                            color='#2D62ED'
                            label={t('configurationInfoContainer.customers')}
                            iconDefault={<EntitiesIcon />}
                            iconIsActive={
                                <EntitiesIconActive className='w-[44px] h-[40px]' />
                            }
                            onClick={() => handleButtonActivation(5)}
                        />
                    </div>
                </div>
            </div>

            {!configuration?.is_future && (
                <p className='text-gray-400'>
                    {t('configurationInfoContainer.impossibleToModify')}
                </p>
            )}

            <div className='px-20 mt-10'>{renderContent()}</div>
        </div>
    );
}
