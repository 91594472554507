import { useTranslation } from 'react-i18next';
import { BsQrCode } from 'react-icons/bs';
import {
    Input,
    Modal,
    Table,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';
import { useState } from 'react';

const ModalGenerateQrCode = ({
    generatedCodes,
    modalGenerateQrCode,
    toggleModalGenerateQrCode,
}) => {
    const { t } = useTranslation();
    const [nbGeneratedCode, setNbGeneratedCode] = useState(null);

    const handleDownload = () => {
        const fileUrl =
            'https://www.osureunion.fr/wp-content/uploads/2022/03/pdf-exemple.pdf'; // à changer
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = 'blank';
        link.download = 'pdf-exemple.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const columns = [
        {
            title: t(
                'modalViewTickets.modalGenerateCodes.tableGeneratedCodes.transCreatedDate',
            ),
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: t(
                'modalViewTickets.modalGenerateCodes.tableGeneratedCodes.transNumberOfCodesGenerated',
            ),
            dataIndex: 'numberOfCodes',
            key: 'numberOfCodes',
        },
        {
            title: t(
                'modalViewTickets.modalGenerateCodes.tableGeneratedCodes.transAction',
            ),
            dataIndex: '',
            key: '',
            render: (text, record) => (
                <Button
                    color={'primary'}
                    onClick={handleDownload}
                    className={'text-xs px-5 py-1.5 my-1.5'}
                >
                    {t(
                        'modalViewTickets.modalGenerateCodes.tableGeneratedCodes.transDownloadFile',
                    )}
                </Button>
            ),
        },
    ];

    const generateCode = () => {
        console.log('nbGeneratedCode', nbGeneratedCode);
        toggleModalGenerateQrCode();
    };

    return (
        <Modal
            isOpen={modalGenerateQrCode}
            onClose={toggleModalGenerateQrCode}
            title={t('modalViewTickets.transGenerateCodes')}
            style={{
                modalTitle: {
                    color: '#25292D',
                    fontSize: '20px',
                    lineHeight: '23px',
                },
            }}
        >
            <div className='flex items-end gap-2 mb-4'>
                <Input
                    label={t(
                        'modalViewTickets.modalGenerateCodes.transNumberCodes',
                    )}
                    style={{ marginBottom: 0 }}
                    placeholder={'20'}
                    onChange={(e) => setNbGeneratedCode(e.target.value)}
                />
                <Button color={'success'} onClick={generateCode}>
                    {t(
                        'modalViewTickets.modalGenerateCodes.transGenerateButton',
                    )}
                </Button>
            </div>
            <div>
                <span className='text-sm font-semibold'>
                    {t(
                        'modalViewTickets.modalGenerateCodes.transCodesAlreadyGenerated',
                    )}
                </span>
                <Table columns={columns} dataSource={generatedCodes} />
            </div>
        </Modal>
    );
};

export default ModalGenerateQrCode;
