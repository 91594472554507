import { useTranslation } from 'react-i18next';
import {
    Input,
    Label,
    Select,
    Table,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { Col, Row } from 'antd';
import { HiQuestionMarkCircle } from 'react-icons/hi2';
import { useState } from 'react';
import CKToolTip from '../../../modUtils/components/CKToolTip/CKToolTip';
import DayCard from '../dayCard/DayCard';
import Button from '../../../modUtils/components/buttons/Button';
import { CgAddR } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { eEquipmentType } from '../../../../commun_global/enums';
import RangePicker from '../../../modUtils/components/componentsLibrary/datePicker/RangePicker';
import { IoClose } from 'react-icons/io5';
import RepeaterTime from '../repeaterTime/RepeaterTime';
import { columns, data } from './constants';
import CheckboxInput from '../../../modUtils/components/componentsLibrary/checkbox/Checkbox';
import moment from 'moment';
import RadioGroup from '../../../modUtils/components/radioGroup/RadioGroup';
import RadioInput from '../../../modUtils/components/radio/RadioInput';
import TooltipLabel from '../../../modUtils/components/tooltipLabel/TooltipLabel';
import { renderError } from '../../../modUtils/functions/renderError';

const ThirdStepperScreen = ({
    values,
    setFieldValue,
    errors,
    handleChange,
    onChange,
    days,
}) => {
    const { t } = useTranslation();
    const [listDays, setListDays] = useState([]);
    const { sites } = useSelector((state) => state.manager);
    const { equipmentTypeGroup } = useSelector((state) => state.equipment);
    const [addNewContraint, setAddNewContraint] = useState(false);
    const [allDay, setAllDay] = useState(false);
    const [specificHours, setSpecificHours] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleRangeChange = (start, end) => {
        // Fonction pour gérer le changement de plage de dates
        if (start) {
            setStartDate(start);
            setFieldValue('startDateOfferUsePromo', moment(start).unix());
        }
        if (end) {
            setEndDate(end);
            setFieldValue('endDateOfferUsePromo', moment(end).unix());
        }
    };

    const style = {
        td: {
            padding: 0,
        },
    };

    const selectDay = (value) => {
        listDays.includes(value)
            ? setListDays(listDays.filter((day) => day !== value))
            : setListDays([...listDays, value]);
        onChange({ target: { name: 'days', value: listDays } });
    };

    const onSelect = (e, name) => {
        const currentValues = values[name] || [];
        setFieldValue(name, [...currentValues, e]);
    };
    const onDeselect = (e, name) => {
        const newData = values[name].filter((site) => site !== e);
        setFieldValue(name, newData);
    };

    return (
        <div className='ThirdStepperScreen'>
            <Row className='border border-[#4D4D4D42] border-solid py-2.5 px-6 sm:px-12 rounded-[5px] sm:w-4/5 w-full mb-4 mx-auto justify-between'>
                <Col lg={11} md={24} sm={24} sx={24}>
                    <TooltipLabel
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transOfferValidityUse',
                        )}
                        tooltipMessage={t(
                            'createPromotionModal.stepperCreatePromotion.transOfferValidityUseTooltipMessage',
                        )}
                    />
                    <RangePicker
                        format={'DD/MM/YYYY'}
                        className={'flex items-center gap-2'}
                        color={'neutral'}
                        errorStartDate={renderError(
                            t,
                            errors,
                            'startDateOfferUsePromo',
                        )}
                        errorEndDate={renderError(
                            t,
                            errors,
                            'endDateOfferUsePromo',
                        )}
                        startLabel={t(
                            'createPromotionModal.stepperCreatePromotion.transFrom',
                        )}
                        endLabel={t(
                            'createPromotionModal.stepperCreatePromotion.transTo',
                        )}
                        onRangeChange={handleRangeChange}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                    />
                </Col>
                <div className='border border-[#4D4D4D42] border-solid'></div>
                <Col lg={11} md={24} sm={24} sx={24}>
                    <div className='flex'>
                        <Label
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transDiscount',
                            )}
                        />
                        <TooltipLabel
                            tooltipMessage={t(
                                'createPromotionModal.stepperCreatePromotion.transDiscountTooltipMessage',
                            )}
                        />
                    </div>
                    <div className='flex gap-4 items-center'>
                        <div className='flex flex-col'>
                            <RadioGroup
                                name={'discountAmount'}
                                defaultValue={values.discountAmount}
                                onChange={handleChange}
                            >
                                <RadioInput
                                    value={0}
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transAmount',
                                    )}
                                />
                                <RadioInput
                                    value={1}
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transDiscountPercent',
                                    )}
                                />
                            </RadioGroup>
                        </div>
                        <Input
                            label={
                                values.discountAmount
                                    ? t(
                                          'createPromotionModal.stepperCreatePromotion.transPercentIn',
                                      )
                                    : t(
                                          'createPromotionModal.stepperCreatePromotion.transAmountIn',
                                      ) + localStorage.getItem('currencySymbol')
                            }
                            name={'amountUsePromo'}
                            type={'number'}
                            min={0}
                            onChange={handleChange}
                            value={values.amountUsePromo}
                            error={renderError(t, errors, 'amountUsePromo')}
                        />
                    </div>
                </Col>
            </Row>
            <Row className='gap-2 w-full justify-between'>
                <Col
                    lg={5}
                    md={24}
                    sm={24}
                    sx={24}
                    className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                >
                    <div className='w-full checkbox-block'>
                        <Label
                            className={'label'}
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transPropriety',
                            )}
                        />
                        <div className='flex-display align-items-baseline gap-1 mb-1'>
                            <CheckboxInput
                                errors={errors}
                                id='uniqueUseCode'
                                name='codeType'
                                checked={values.maximumPrintingUsePromo === '1'}
                                value={'uniqueUseCode'}
                                onChange={(e) =>
                                    setFieldValue(
                                        'maximumPrintingUsePromo',
                                        e.target.checked ? '1' : '',
                                    )
                                }
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transUniqueUseCode',
                                )}
                                error={renderError(
                                    t,
                                    errors,
                                    'maximumPrintingUsePromo',
                                )}
                            />
                            <TooltipLabel
                                tooltipMessage={t(
                                    'createPromotionModal.stepperCreatePromotion.transUniqueUseCodeDescription',
                                )}
                            />
                        </div>
                        <div className='flex-display align-items-baseline gap-1'>
                            <CheckboxInput
                                id='usedManyTimeCode'
                                name='codeType'
                                checked={
                                    values.maximumPrintingUsePromo ===
                                    'illimited'
                                }
                                value={'illimited'}
                                onChange={(e) =>
                                    setFieldValue(
                                        'maximumPrintingUsePromo',
                                        e.target.checked ? 'illimited' : '',
                                    )
                                }
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transUsedManyTimeCode',
                                )}
                                error={renderError(
                                    t,
                                    errors,
                                    'maximumPrintingUsePromo',
                                )}
                            />
                            <TooltipLabel
                                tooltipMessage={t(
                                    'createPromotionModal.stepperCreatePromotion.transUsedManyTimeCodeDescription',
                                )}
                            />
                        </div>
                        <Input
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transMaxUse',
                            )}
                            placeholder={
                                values.maximumPrintingUsePromo === '1'
                                    ? `1 ${t('createPromotionModal.stepperCreatePromotion.transTimes')}`
                                    : values.maximumPrintingUsePromo ===
                                        'illimited'
                                      ? t(
                                            'createPromotionModal.stepperCreatePromotion.transUnlimited',
                                        )
                                      : `0 ${t(
                                            'createPromotionModal.stepperCreatePromotion.transTimes',
                                        )}`
                            }
                            disabled={
                                values.maximumPrintingUsePromo ===
                                    'illimited' ||
                                values.maximumPrintingUsePromo === '1'
                            }
                            value={values.maximumPrintingUsePromo}
                            id='maximumPrinting'
                            name={'maximumPrintingUsePromo'}
                            onChange={handleChange}
                            width={'100%'}
                            min={0}
                            type={'number'}
                            classnames={'h-12 w-full'}
                            error={renderError(
                                t,
                                errors,
                                'maximumPrintingUsePromo',
                            )}
                        />
                        <Label
                            label={`${t(
                                'createPromotionModal.stepperCreatePromotion.transDailyValidity',
                            )}*`}
                        />
                        <div className='flex gap-2'>
                            <Input
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transStarting',
                                )}
                                placeholder={'0'}
                                min={0}
                                type={'number'}
                                value={values.startDayValidityUsePromo}
                                onChange={handleChange}
                                name={'startDayValidityUsePromo'}
                                id={'startDayValidityUsePromo'}
                                error={renderError(
                                    t,
                                    errors,
                                    'startDayValidityUsePromo',
                                )}
                            />
                            <Input
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transUpTo',
                                )}
                                min={0}
                                type={'number'}
                                placeholder={'10'}
                                value={values.endDayValidityUsePromo}
                                onChange={handleChange}
                                name={'endDayValidityUsePromo'}
                                id={'endDayValidityUsePromo'}
                                error={renderError(
                                    t,
                                    errors,
                                    'endDayValidityUsePromo',
                                )}
                            />
                        </div>
                        <p className='mb-0'>
                            0 ={' '}
                            {t(
                                'createPromotionModal.stepperCreatePromotion.transDDay',
                            )}
                        </p>
                    </div>
                </Col>
                <Col
                    lg={5}
                    md={24}
                    sm={24}
                    sx={24}
                    className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                >
                    <TooltipLabel
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transWhere',
                        )}
                        tooltipMessage={t(
                            'createPromotionModal.stepperCreatePromotion.transWhereUseToolitipMessage',
                        )}
                    />
                    <div>
                        <Select
                            onSelect={(value) =>
                                onSelect(value, 'sitesUsePromo')
                            }
                            onDeselect={(value) =>
                                onDeselect(value, 'sitesUsePromo')
                            }
                            multiple
                            error={renderError(t, errors, 'sitesUsePromo')}
                            style={{ selectLabel: { color: '#25292D' } }}
                            selectLabel={t(
                                'createPromotionModal.stepperCreatePromotion.transSite',
                            )}
                            containerWidth={'full'}
                            dropdownMatchSelectWidth
                            msgDisplayedWhenNoOptions={t(
                                'createPromotionModal.stepperCreatePromotion.transNoData',
                            )}
                            defaultValue={values.sitesUsePromo}
                            block
                            name='sitesUsePromo'
                            placeholder={t(
                                'createPromotionModal.stepperCreatePromotion.transSelect',
                            )}
                            options={sites?.map((site) => ({
                                value: site.id,
                                label: site.name,
                            }))}
                            selectedMessage={
                                values.sitesUsePromo?.length
                                    ? `${values.sitesUsePromo?.length} ${t(
                                          'createPromotionModal.stepperCreatePromotion.transSelectedSites',
                                      )}`
                                    : t(
                                          'createPromotionModal.stepperCreatePromotion.transNoSitesSelected',
                                      )
                            }
                        />
                    </div>
                    <div className='mt-2'>
                        <Select
                            onSelect={(value) =>
                                onSelect(value, 'equipementsTypeUsePromo')
                            }
                            onDeselect={(value) =>
                                onDeselect(value, 'equipementsTypeUsePromo')
                            }
                            multiple
                            style={{ selectLabel: { color: '#25292D' } }}
                            selectLabel={t(
                                'createPromotionModal.stepperCreatePromotion.transEquipmentType',
                            )}
                            containerWidth={'full'}
                            dropdownMatchSelectWidth
                            msgDisplayedWhenNoOptions={t(
                                'createPromotionModal.stepperCreatePromotion.transNoData',
                            )}
                            defaultValue={values.equipementsTypeUsePromo}
                            error={renderError(
                                t,
                                errors,
                                'equipementsTypeUsePromo',
                            )}
                            block
                            name='equipementType'
                            placeholder={t(
                                'createPromotionModal.stepperCreatePromotion.transSelect',
                            )}
                            options={equipmentTypeGroup?.map(
                                (equipmentType) => ({
                                    label: t(
                                        eEquipmentType[equipmentType.type],
                                    ),
                                    value: equipmentType.type,
                                }),
                            )}
                            selectedMessage={
                                values.equipementsTypeUsePromo?.length
                                    ? `${values.equipementsTypeUsePromo?.length} ${t(
                                          'createPromotionModal.stepperCreatePromotion.transSelectedEquipmentType',
                                      )}`
                                    : t(
                                          'createPromotionModal.stepperCreatePromotion.transNoEquipmentTypeSelected',
                                      )
                            }
                        />
                    </div>
                </Col>
                <Col
                    lg={8}
                    md={24}
                    sm={24}
                    sx={24}
                    className='border border-[#4D4D4D42] border-solid rounded-[5px]'
                >
                    {!addNewContraint ? (
                        <div className='p-2'>
                            <TooltipLabel
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transWhen',
                                )}
                                tooltipMessage={t(
                                    'createPromotionModal.stepperCreatePromotion.transWhenUseToolitipMessage',
                                )}
                            />
                            <Table
                                style={style}
                                columns={columns(t)}
                                dataSource={data}
                            />
                            <div className='flex justify-center mt-4'>
                                <Button
                                    color={'dark'}
                                    onClick={() => setAddNewContraint(true)}
                                    className={
                                        'font-bold text-xs w-auto shadow-none flex-display align-items-center gap-2'
                                    }
                                >
                                    <CgAddR />{' '}
                                    {t(
                                        'createPromotionModal.stepperCreatePromotion.transAddContraint',
                                    )}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className='bg-white rounded'>
                            <div className='flex justify-end p-2'>
                                <IoClose
                                    onClick={() => setAddNewContraint(false)}
                                    color='#1B8ADC'
                                    fontSize={20}
                                    className='cursor-pointer'
                                />
                            </div>
                            <div className='p-5'>
                                <div className='flex-display align-items-baseline'>
                                    <CheckboxInput
                                        errors={errors}
                                        id='everyDay'
                                        name='days'
                                        value={'everyDay'}
                                        checked={allDay}
                                        onChange={(e) =>
                                            setAllDay(e.target.checked)
                                        }
                                        label={t(
                                            'createPromotionModal.stepperCreatePromotion.transEveryDay',
                                        )}
                                    />
                                </div>
                                <div className='flex-display align-items-center justify-content-center mt-4 gap-2'>
                                    {days?.map((day) => (
                                        <DayCard
                                            day={day}
                                            selectDay={selectDay}
                                            selected={
                                                listDays.includes(day) || allDay
                                            }
                                        />
                                    ))}
                                </div>
                                <div className='flex-display align-items-baseline mt-4'>
                                    <CheckboxInput
                                        errors={errors}
                                        id='specificHours'
                                        name='hours'
                                        value={'specificHours'}
                                        checked={specificHours}
                                        onChange={(e) =>
                                            setSpecificHours(e.target.checked)
                                        }
                                        label={t(
                                            'createPromotionModal.stepperCreatePromotion.transSpecificHours',
                                        )}
                                    />
                                </div>
                                <RepeaterTime
                                    startTimeLabel={t(
                                        'createPromotionModal.stepperCreatePromotion.transFromTime',
                                    )}
                                    endTimeLabel={t(
                                        'createPromotionModal.stepperCreatePromotion.transToTime',
                                    )}
                                    disabled={!specificHours}
                                />
                                <div className='flex items-center justify-center mt-4 gap-2'>
                                    <Button
                                        color={'success'}
                                        className={'font-bold text-xs'}
                                    >
                                        {t(
                                            'createPromotionModal.transValidateButton',
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </Col>
                <Col
                    lg={5}
                    md={24}
                    sm={24}
                    sx={24}
                    className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                >
                    <TooltipLabel
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transWhy',
                        )}
                        tooltipMessage={t(
                            'createPromotionModal.stepperCreatePromotion.transWhyUseToolitipMessage',
                        )}
                    />
                    <div className='flex gap-2'>
                        <RadioGroup
                            defaultValue={'amount'}
                            name={'amount'}
                            onChange={handleChange}
                        >
                            <div className='flex'>
                                <RadioInput
                                    value={'amount'}
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transAmount',
                                    )}
                                />
                                <RadioInput
                                    value={'selection'}
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transSelection',
                                    )}
                                />
                            </div>
                        </RadioGroup>
                    </div>
                    <Label
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transForPurchase',
                        )}
                    />
                    <p className='text-sm'>
                        {values.amount === 'amount'
                            ? t(
                                  'createPromotionModal.stepperCreatePromotion.transAmountDescription',
                              ) + localStorage.getItem('currencySymbol')
                            : t(
                                  'createPromotionModal.stepperCreatePromotion.transSelectionDescription',
                              )}
                    </p>

                    {values.amount === 'amount' ? (
                        <div className='flex gap-2 '>
                            <Input
                                onChange={handleChange}
                                name='minUsePromo'
                                label={
                                    t(
                                        'createPromotionModal.stepperCreatePromotion.transMin',
                                    ) + localStorage.getItem('currencySymbol')
                                }
                                value={values.minUsePromo}
                                id='minUsePromo'
                                width={'100%'}
                                type='number'
                                min={0}
                                step='0.01'
                                placeholder='0,00'
                                error={renderError(t, errors, 'minUsePromo')}
                            />
                            <Input
                                onChange={handleChange}
                                name='maxUsePromo'
                                label={
                                    t(
                                        'createPromotionModal.stepperCreatePromotion.transMax',
                                    ) + localStorage.getItem('currencySymbol')
                                }
                                value={values.maxUsePromo}
                                id='maxUsePromo'
                                width={'100%'}
                                type='number'
                                min={0}
                                step='0.01'
                                placeholder='0,00 €'
                                error={renderError(t, errors, 'maxUsePromo')}
                            />
                        </div>
                    ) : (
                        <Input
                            label={
                                <TooltipLabel
                                    label={`${t('createPromotionModal.stepperCreatePromotion.transSelectionLabel')}`}
                                    tooltipMessage={t(
                                        'createPromotionModal.stepperCreatePromotion.transSelectionTooltipMessage',
                                    )}
                                />
                            }
                            placeholder={'1, 2, 3, 4, 5, 6,'}
                            value={values.idsUsePromo}
                            name={'idsUsePromo'}
                            id={'idsUsePromo'}
                            onChange={handleChange}
                            error={renderError(t, errors, 'idsUsePromo')}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default ThirdStepperScreen;
