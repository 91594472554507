import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TablePaginatedScroll from '../../modUtils/components/componentsLibrary/table/TablePaginatedScroll';
import {
    Modal,
    Spinner,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import {
    support_centralized_paginated_get,
    support_centralized_create,
} from '../../modSupportCentralized/slices/supportCentralizedSlices';
import Button from '../../modUtils/components/buttons/Button';
import eSupportFormat from '../../../commun_global/js/support/e_support_format';
import { dateFormat } from '../../modUtils/functions/dateFormat';

export default function EntitySupports({ entity }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const [modalVisible, setModalVisible] = useState(false);
    const [supports, setSupports] = useState(null);
    const { supportCentralizedCount } = useSelector(
        (state) => state.supportCentralized,
    );

    function handleClick(supports) {
        setSupports(supports);
        setModalVisible(true);
    }

    const columns = [
        {
            title: t('entitySupports.type'),
            dataIndex: 'support_format',
            key: 'support_format',
            width: '25%',
        },
        {
            title: t('entitySupports.supportNumber'),
            dataIndex: 'support_number',
            key: 'support_number',
            width: '25%',
        },
        {
            title: t('entitySupports.lastUsed'),
            dataIndex: 'last_visit_date',
            key: 'last_visit_date',
            render: (text, record) =>
                record.last_visit_date
                    ? dateFormat(record.last_visit_date)
                    : t('entitySupports.none'),
            width: '25%',
        },
        {
            title: t('entitySupports.qrCode'),
            dataIndex: 'support_dematerialized',
            key: 'support_dematerialized',
            render: (text, record) =>
                text && (
                    <img
                        onClick={() => handleClick(record)}
                        className='w-8'
                        src={text}
                    />
                ),
            width: '25%',
        },
    ];

    const getSupportTransactions = async (limit, offset) => {
        const response = await dispatch(
            support_centralized_paginated_get({
                groupId: groupId,
                entityId: entity?.id,
                limit,
                offset,
            }),
        ).unwrap();
        return response;
    };

    const createSupportCentralized = async () => {
        setLoading(true);
        // Fonction pour trouver la clé en fonction de la valeur
        function getKeyByValue(obj, value) {
            return Object.keys(obj).find((key) => obj[key] === value);
        }
        const supportQRCode = getKeyByValue(
            eSupportFormat,
            'eSupportFormat.qrCode',
        );
        await dispatch(
            support_centralized_create({
                groupId: groupId,
                entityId: entity?.id,
                supportFormat: supportQRCode,
            }),
        ).unwrap();
        setLoading(false);
    };

    return (
        <>
            {entity && (
                <>
                    {loading ? (
                        <Spinner />
                    ) : (
                        <>
                            {supportCentralizedCount === 0 ? (
                                <Button
                                    onClick={() => createSupportCentralized()}
                                    color='primary'
                                    className='h-10 uppercase'
                                    size='sm'
                                >
                                    {t('entitySupports.createQRCode')}
                                </Button>
                            ) : (
                                <>
                                    <TablePaginatedScroll
                                        label={t(
                                            'entitySupports.identificationAndPaymentMedia',
                                        )}
                                        fetch={getSupportTransactions}
                                        effect={entity}
                                        columns={columns}
                                    />
                                    <Modal
                                        title={t('entitySupports.qrCode')}
                                        isOpen={modalVisible}
                                        onClose={() => setModalVisible(false)}
                                    >
                                        <img
                                            src={
                                                supports?.support_dematerialized
                                            }
                                        />
                                    </Modal>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}
