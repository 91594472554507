import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { eEquipmentType } from '../../../commun_global/enums';
import { constraint_fincancial_entity_cumuls_list_get } from '../../modProfil/slices/constraintFincancialEntityCumulsSlice';
import { getPeriodLabel } from '../../modProfil/functions/handleDaysOfWeek';
import { ReactComponent as BlockedSvg } from '../assets/images/blocked.svg';
import TablePaginatedScroll from '../../modUtils/components/componentsLibrary/table/TablePaginatedScroll';
import { amountFormatWithCurrencySymbol } from '../../modUtils/functions/numberFormat';

export default function EntityAccounts({ entity }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);

    // TODO CH UTILISÉ ENUM QUAND ELLE SERA DISPO
    const E_CONSTRAINT_FINANCIAL_LIMIT_UNIT_BASE = {
        1: t('addFinancialConstraintModal.amount'),
        2: t('addFinancialConstraintModal.usageCount'),
    };

    const E_CONSTRAINT_FINANCIAL_LIMIT_UNIT = Object.fromEntries(
        Object.entries(E_CONSTRAINT_FINANCIAL_LIMIT_UNIT_BASE).map(
            ([key, value]) => [value, parseInt(key)],
        ),
    );

    const columns = [
        {
            title: t('entityAccounts.equipmentType'),
            dataIndex: 'equipment_type',
            key: 'equipment_type',
            width: '20%',
            render: (text, record) =>
                record.constraint_financial.equipment_type
                    ? t(
                          eEquipmentType[
                              record.constraint_financial.equipment_type
                          ],
                      )
                    : t('entityAccounts.all'),
        },
        {
            title: t('entityAccounts.period'),
            dataIndex: 'period_type',
            key: 'period_type',
            render: (text, record) =>
                getPeriodLabel(record.constraint_financial.period_type),
            width: '20%',
        },
        {
            title: t('entityAccounts.cumulative'),
            dataIndex: 'value',
            key: 'value',
            width: '20%',
            render: (value, record) =>
                record?.constraint_financial?.limit_unit ===
                E_CONSTRAINT_FINANCIAL_LIMIT_UNIT[
                    t('addFinancialConstraintModal.amount')
                ]
                    ? amountFormatWithCurrencySymbol(value)
                    : Number(value),
        },
        {
            title: t('entityAccounts.limit'),
            dataIndex: 'amount_max',
            key: 'amount_max',
            width: '20%',
            render: (text, record) =>
                record?.constraint_financial?.limit_unit ===
                E_CONSTRAINT_FINANCIAL_LIMIT_UNIT[
                    t('addFinancialConstraintModal.amount')
                ]
                    ? amountFormatWithCurrencySymbol(
                          record.constraint_financial.amount_max,
                      )
                    : record.constraint_financial.usage_max,
        },
        {
            title: t('entityAccounts.cumulativeReached'),
            render: (text, record) => record.is_reached && <BlockedSvg />,
            width: '20%',
        },
    ];

    const getConstraintFincancialEntityCumuls = async (limit, offset) => {
        const response = await dispatch(
            constraint_fincancial_entity_cumuls_list_get({
                groupId: groupId,
                entityId: entity?.id,
                limit,
                offset,
            }),
        ).unwrap();
        return response;
    };

    return (
        <>
            {entity && (
                <TablePaginatedScroll
                    label={t('entityAccounts.accumulationHistory')}
                    fetch={getConstraintFincancialEntityCumuls}
                    effect={entity}
                    columns={columns}
                />
            )}
        </>
    );
}
