import { useEffect, useState } from 'react';
import HeaderPromoPage from '../../components/headerPromoPage/HeaderPromoPage';
import PromoCard from '../../components/promoCard/PromoCard';
import ModalTickets from '../../components/modalTickets/ModalTickets';
import ModalGenerateQrCode from '../../components/modalGenerateQrCode/ModalGenerateQrCode';
import ModalCloseTicketsGroup from '../../components/modalCloseTicketsGroup/ModalCloseTicketsGroup';
import ExportModalTickets from '../../components/exportModalTickets/ExportModalTickets';
import ModalTestPromo from '../../components/modalTestPromo/ModalTestPromo';
import ModalSendTicketMail from '../../components/modalSendTicketMail/ModalSendTicketMail';
import ModalCloseTicket from '../../components/modalCloseTicket/ModalCloseTicket';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_promotion_detail,
    get_promotions_paginated,
} from '../../slices/promotionSlice';
import NoData from '../../../modUtils/components/noData/NoData';
import { useTranslation } from 'react-i18next';
import ModalCreatePromotion from '../../components/modalCreatePromotion/ModalCreatePromotion';
import { Spinner } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { dateFormat } from '../../../modUtils/functions/dateFormat';
import { get_sites_by_group_paginated } from '../../../modManager/slices/managerSlice';
import { get_types_by_group_paginated } from '../../../modEquipment/slices/equipmentSlice';
import i18next from 'i18next';

const PromotionsPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId } = useSelector((state) => state.manager);
    const { promotions, loading, promotionDetail } = useSelector(
        (state) => state.promotion,
    );
    const [promotionType, setPromotionType] = useState(null);
    const [promo, setPromo] = useState(null);
    const [modalCreatePromotionOpen, setModalCreatePromotionOpen] =
        useState(false);
    const [modal, setModal] = useState(false);
    const [modalGenerateQrCode, setModalGenerateQrCode] = useState(false);
    const [modalCloseTicketsGroup, setModalCloseTicketsGroup] = useState(false);
    const [modalExportTicket, setModalExportTicket] = useState(false);
    const [modalTestPromo, setModalTestPromo] = useState(false);
    const [modalSendTicketMail, setModalSendTicketMail] = useState(false);
    const [modalCloseTicket, setModalCloseTicket] = useState(false);
    const [promotionId, setPromotionId] = useState(null);
    const formatDate = 'DD/MM/YYYY HH:mm';
    const [startDate, setStartDate] = useState(
        moment().subtract(6, 'days').startOf('day').format(formatDate),
    );
    const [endDate, setEndDate] = useState(
        moment().endOf('day').format(formatDate),
    );
    const [selectForm, setSelectForm] = useState({
        promotion: 'all',
        status: 'all',
        dateStart: startDate,
        dateEnd: endDate,
    });
    const [initialValues, setInitialValues] = useState({
        promotionName: null,
        description: null,
        typePromo: 1,
        enableObtainPromo: false,
        startDateOfferObtainPromo: null,
        endDateOfferObtainPromo: null,
        maximumPrintingObtainPromo: null,
        sitesObtainPromo: null,
        equipementsTypeObtainPromo: null,
        constraintObtainPromo: [],
        minObtainPromo: null,
        maxObtainPromo: null,
        startDateOfferUsePromo: null,
        endDateOfferUsePromo: null,
        discountAmount: 0,
        amountUsePromo: null,
        maximumPrintingUsePromo: '',
        codeTypeUsepromo: null,
        startDayValidityUsePromo: 0,
        endDayValidityUsePromo: null,
        sitesUsePromo: null,
        equipementsTypeUsePromo: null,
        constraintUsePromo: [],
        minUsePromo: null,
        maxUsePromo: null,
        amountUsePromo: null,
        idsUsePromo: null,
        ticket_template_sms: null,
        ticket_template_email: null,
        ticket_template_paper: null,
        amount: 'amount',
    });

    useEffect(() => {
        if (groupId)
            dispatch(
                get_promotions_paginated({
                    groupId,
                    promotion_type: promotionType,
                    limit: 1000,
                    offset: 0,
                }),
            );
    }, [groupId, promotionType, i18next.language]);

    useEffect(() => {
        if (groupId) {
            dispatch(
                get_sites_by_group_paginated({
                    limit: 100,
                    offset: 0,
                    groupId: groupId,
                }),
            );
            dispatch(get_types_by_group_paginated(groupId));
        }
    }, [groupId, i18next.language]);

    useEffect(() => {
        if (promotionDetail)
            setInitialValues({
                promotionName: promotionDetail.name,
                identifier: promotionDetail.id,
                description: promotionDetail.description,
                typePromo: promotionDetail.promotion_type,
                enableObtainPromo: false,
                startDateOfferObtainPromo: dateFormat(
                    promotionDetail.criteria_obtaining?.date_start,
                    'DD/MM/YYYY',
                ),
                endDateOfferObtainPromo: dateFormat(
                    promotionDetail.criteria_obtaining?.date_end,
                    'DD/MM/YYYY',
                ),
                maximumPrintingObtainPromo:
                    promotionDetail.criteria_obtaining?.count_distribution_max,
                sitesObtainPromo: promotionDetail.criteria_obtaining?.site_ids,
                equipementsTypeObtainPromo:
                    promotionDetail.criteria_obtaining?.equipment_types,
                constraintObtainPromo: [],
                minObtainPromo: promotionDetail.criteria_obtaining?.amount_min,
                maxObtainPromo: promotionDetail.criteria_obtaining?.amount_max,
                startDateOfferUsePromo: dateFormat(
                    promotionDetail.criteria_usage?.date_start,
                    'DD/MM/YYYY',
                ),
                endDateOfferUsePromo: dateFormat(
                    promotionDetail.criteria_usage?.date_end,
                    'DD/MM/YYYY',
                ),
                discountAmount: promotionDetail.amount_promo_type,
                amountUsePromo: promotionDetail.amount,
                maximumPrintingUsePromo:
                    !promotionDetail.criteria_usage?.count_usage_max ? 'illimited' : promotionDetail.criteria_usage?.count_usage_max === 1 ? '1' : promotionDetail.criteria_usage?.count_usage_max,
                startDayValidityUsePromo:
                    promotionDetail.criteria_usage?.lifetime_start_days,
                endDayValidityUsePromo: promotionDetail.criteria_usage?.lifetime,
                sitesUsePromo: promotionDetail.criteria_usage?.site_ids,
                equipementsTypeUsePromo:
                    promotionDetail.criteria_usage?.equipment_types,
                constraintUsePromo: [],
                minUsePromo: promotionDetail.criteria_usage?.amount_max,
                maxUsePromo: promotionDetail.criteria_usage?.amount_max,
                idsUsePromo:
                    promotionDetail.criteria_usage?.selection_ids?.join(','),
                    amount: promotionDetail.criteria_usage?.selection_ids ? 'selection' : 'amount',
            });
    }, [promotionDetail]);

    const toogleCreatePromotionModal = async (value) => {
        setPromotionId(value);
        if (value)
            dispatch(get_promotion_detail({ groupId, promotionId: value }));
        await setModalCreatePromotionOpen((prevModal) => !prevModal);
    };

    const toggleModalTickets = (promo) => {
        setPromo(promo);
        setModal((prevModal) => !prevModal);
    };
    const toggleModalGenerateQrCode = async () => {
        toggleModalTickets();
        setModalGenerateQrCode((prevModal) => !prevModal);
    };

    const toggleModalCloseTicketsGroup = () => {
        setModalCloseTicketsGroup((prevModal) => !prevModal);
        toggleModalTickets();
    };

    const toggleModalExportTicket = () => {
        setModalExportTicket((prevModal) => !prevModal);
        toggleModalTickets();
    };

    const toggleModalTestPromo = () => {
        toggleModalTickets();
        setModalTestPromo((prevModal) => !prevModal);
    };

    const toggleModalSendTicketMail = () => {
        toggleModalTickets();
        setModalSendTicketMail(
            (prevModalSendTicketMail) => !prevModalSendTicketMail,
        );
    };

    const toggleModalCloseTicket = () => {
        toggleModalTickets();
        setModalCloseTicket(
            (prevModalSendTicketMail) => !prevModalSendTicketMail,
        );
    };

    const closeModalTickets = () => {
        setModal(false);
    };
    return (
        <div className='PromotionsPage px-4 sm:px-14 py-8'>
            <HeaderPromoPage
                toggleModalTickets={toggleModalTickets}
                toogleCreatePromotionModal={toogleCreatePromotionModal}
                setPromotionType={setPromotionType}
            />
            {loading ? (
                <div className='flex items-center justify-center h-full py-24'>
                    <Spinner />
                </div>
            ) : promotions && promotions.length ? (
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    {promotions.map((promotion) => (
                        <div key={promotion.id} className='h-full w-full'>
                            <PromoCard
                                promo={promotion}
                                toggleModalTickets={toggleModalTickets}
                                toogleCreatePromotionModal={
                                    toogleCreatePromotionModal
                                }
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <NoData text={t('transNoData')} />
            )}
            {modal && (
                <ModalTickets
                    promo={promo}
                    modal={modal}
                    closeModalTickets={closeModalTickets}
                    toggleModalTickets={toggleModalTickets}
                    modalGenerateQrCode={modalGenerateQrCode}
                    toggleModalGenerateQrCode={toggleModalGenerateQrCode}
                    toggleModalCloseTicketsGroup={toggleModalCloseTicketsGroup}
                    toggleModalExportTicket={toggleModalExportTicket}
                    toggleModalTestPromo={toggleModalTestPromo}
                    toggleModalSendTicketMail={toggleModalSendTicketMail}
                    toggleModalCloseTicket={toggleModalCloseTicket}
                    selectForm={selectForm}
                    setSelectForm={setSelectForm}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                />
            )}
            {modalGenerateQrCode && (
                <ModalGenerateQrCode
                    generatedCodes={promo?.generatedCodes}
                    toggleModalTickets={closeModalTickets}
                    modalGenerateQrCode={modalGenerateQrCode}
                    toggleModalGenerateQrCode={toggleModalGenerateQrCode}
                />
            )}
            {modalCloseTicketsGroup && (
                <ModalCloseTicketsGroup
                    toggleModalCloseTicketsGroup={toggleModalCloseTicketsGroup}
                    modalCloseTicketsGroup={modalCloseTicketsGroup}
                />
            )}
            {modalExportTicket && (
                <ExportModalTickets
                    selectForm={selectForm}
                    modalExportTicket={modalExportTicket}
                    toggleModalExportTicket={toggleModalExportTicket}
                />
            )}
            {modalTestPromo && (
                <ModalTestPromo
                    modalTestPromo={modalTestPromo}
                    toggleModalTestPromo={toggleModalTestPromo}
                />
            )}
            {modalSendTicketMail && (
                <ModalSendTicketMail
                    toggleModalSendTicketMail={toggleModalSendTicketMail}
                    modalSendTicketMail={modalSendTicketMail}
                />
            )}
            {modalCloseTicket && (
                <ModalCloseTicket
                    toggleModalCloseTicket={toggleModalCloseTicket}
                    modalCloseTicket={modalCloseTicket}
                />
            )}
            {modalCreatePromotionOpen && (
                <ModalCreatePromotion
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    modalCreatePromotionOpen={modalCreatePromotionOpen}
                    setModalCreatePromotion={setModalCreatePromotionOpen}
                    groupId={groupId}
                    promotionId={promotionId}
                />
            )}
        </div>
    );
};

export default PromotionsPage;
