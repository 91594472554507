import { FaRegTrashAlt } from "react-icons/fa";
import { HiOutlinePencil } from "react-icons/hi2";

export const days = (t) => [
    t('createPromotionModal.stepperCreatePromotion.transMonday'),
    t('createPromotionModal.stepperCreatePromotion.transTuesday'),
    t('createPromotionModal.stepperCreatePromotion.transWednesday'),
    t('createPromotionModal.stepperCreatePromotion.transThursday'),
    t('createPromotionModal.stepperCreatePromotion.transFriday'),
    t('createPromotionModal.stepperCreatePromotion.transSaturday'),
    t('createPromotionModal.stepperCreatePromotion.transSunday'),
];

export const columns = (t) => [
    {
        title: t(
            'createPromotionModal.stepperCreatePromotion.table.transDays',
        ),
        dataIndex: 'days',
        key: 'days',
        width: '50%',
        render: (text, record) => (
            <div className='px-[5px]'>
                <span className='text-xs text-wrap'>{text.join(', ')}</span>
            </div>
        ),
    },
    {
        title: t(
            'createPromotionModal.stepperCreatePromotion.table.transScheduleFrom',
        ),
        dataIndex: 'schedules',
        key: 'schedules',
        td: { padding: 0 },
        render: (schedules) => (
            <div>
                {schedules.map((schedule, index) => (
                    <div
                        className={`py-1${index !== schedules.length - 1 && ' border-b border-t-0 border-x-0 border-solid border-b-[#D1D8DD]'}`}
                        key={index}
                    >
                        {schedule.from}
                    </div>
                ))}
            </div>
        ),
    },
    {
        title: t(
            'createPromotionModal.stepperCreatePromotion.table.transScheduleTo',
        ),
        dataIndex: 'schedules',
        key: 'schedules',
        render: (schedules) => (
            <div>
                {schedules.map((schedule, index) => (
                    <div
                        className={`py-1${index !== schedules.length - 1 && ' border-b border-t-0 border-x-0 border-solid border-b-[#D1D8DD]'}`}
                        key={index}
                    >
                        {schedule.to}
                    </div>
                ))}
            </div>
        ),
    },
    {
        title: t(
            'createPromotionModal.stepperCreatePromotion.table.transActions',
        ),
        dataIndex: '',
        key: '',
        render: (text, record) => (
            <div className='actions-table flex items-center justify-around'>
                <HiOutlinePencil className='cursor-pointer' fontSize={15} />
                <FaRegTrashAlt
                    className='cursor-pointer'
                    color='#ff0000'
                    fontSize={15}
                />
            </div>
        ),
    },
];

export const data = [
    {
        days: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
        schedules: [
            { from: '10:00', to: '18:00' },
            { from: '10:00', to: '18:00' },
            { from: '10:00', to: '18:00' },
        ],
    },
    {
        days: ['Lun', 'Mar', 'Ven', 'Sam', 'Dim'],
        schedules: [{ from: '10:00', to: '18:00' }],
    },
    {
        days: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven'],
        schedules: [
            { from: '10:00', to: '18:00' },
            { from: '10:00', to: '18:00' },
        ],
    },
];

export const variable_array = (t) => [
    {
        key: t('createPromotionModal.stepperCreatePromotion.transQrCode'),
        variable: 'QRCODE.CMD',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transBarCode'),
        variable: 'QRCODE.VALUE',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transNamePromotion'),
        variable: 'NAME',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transDescription'),
        variable: 'DESCRIPTION',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.showCard.transTypePromotion'),
        variable: 'PROMOTION_TYPE',
    },

    {
        key: t('createPromotionModal.stepperCreatePromotion.transValidityStart'),
        variable: 'VALIDITY_START',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transValidityEnd'),
        variable: 'VALIDITY_END',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transSite'),
        variable: 'SITES',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transEquipmentTypeVariable'),
        variable: 'EQUIPMENTS.TYPE',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transUseMax'),
        variable: 'USAGEMAX',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transLifeTimeStart'),
        variable: 'LIFETIME_START_DAYS',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transLifeTime'),
        variable: 'LIFETIME',
    },
]

export const style_array = (t) => [
    {
        key: t('createPromotionModal.stepperCreatePromotion.transLeftVariable'),
        variable: 'LEFT',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.trabnsRightVariable'),
        variable: 'RIGHT',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transCenterVariable'),
        variable: 'CENTER',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transNormalSize'),
        variable: 'SIZE_NORMAL',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transDoubleSize'),
        variable: 'SIZE_DOUBLE',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transBoldVariable'),
        variable: 'BOLD',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transItalic'),
        variable: 'ITALIC',
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transSkipLineVariable'),
        variable: 'LF',
    },
]