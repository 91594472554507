import { useTranslation } from 'react-i18next';
import {
    Button,
    Textarea,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { useState } from 'react';
import LastStepperScreen from './LastStepperScreen';
import { style_array, variable_array } from './constants';
import { FaCheck } from 'react-icons/fa';

const FourthStepperScreen = ({ values, last, setFieldValue }) => {
    const { t } = useTranslation();
    const [cardFormat, setCardFormat] = useState('email');

    const [template, setTemplate] = useState({
        data: null,
        props: {
            module_name: null,
            template_name: null,
            variables_available: null,
            interpretor: {
                commands_available: null,
                line_length_max: 0,
                total_length_max: 0,
            },
        },
    });

    const handleDoubleClickVariables = (variable) => {
        // Create a copy of the current variables_available array
        const updatedVariables = template.props.variables_available
            ? [...template.props.variables_available, variable]
            : [variable];

        // Set the new template state
        setTemplate((prevTemplate) => ({
            ...prevTemplate,
            props: {
                ...prevTemplate.props,
                variables_available: updatedVariables,
            },
        }));

        // Update the field value based on the selected card format
        if (cardFormat === 'email') {
            setFieldValue('ticket_template_email', template);
        } else if (cardFormat === 'sms') {
            setFieldValue('ticket_template_sms', template);
        } else {
            setFieldValue('ticket_template_paper', template);
        }
    };

    const handleDoubleClickStyle = (variable) => {
        // Create a copy of the current commands_available array
        const updatedCommands = template.props.interpretor.commands_available
            ? [...template.props.interpretor.commands_available, variable]
            : [variable];

        // Set the new template state
        setTemplate((prevTemplate) => ({
            ...prevTemplate,
            props: {
                ...prevTemplate.props,
                interpretor: {
                    ...prevTemplate.props.interpretor,
                    commands_available: updatedCommands,
                },
            },
        }));

        // Update the field value based on the selected card format
        if (cardFormat === 'email') {
            setFieldValue('ticket_template_email', template);
        } else if (cardFormat === 'sms') {
            setFieldValue('ticket_template_sms', template);
        } else {
            setFieldValue('ticket_template_paper', template);
        }
    };

    return (
        <div className='FourthStepperScreen'>
            {last ? (
                <LastStepperScreen values={values} />
            ) : (
                <>
                    <div className='flex flex-col mb-8 items-center'>
                        <p className='text-sm font-semibold'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.transCardFormat',
                            )}
                        </p>
                        <div className='flex gap-4 align-items-center'>
                            <Button
                                type={
                                    cardFormat === 'email'
                                        ? 'primary'
                                        : 'default'
                                }
                                color='primary'
                                className='h-10 w-auto whitespace-nowrap text-sm font-normal'
                                size='sm'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCardFormat('email');
                                }}
                            >
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transEmail',
                                )}
                            </Button>
                            <Button
                                type={
                                    cardFormat === 'sms' ? 'primary' : 'default'
                                }
                                color='primary'
                                className='h-10 w-auto whitespace-nowrap text-sm font-normal'
                                size='sm'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCardFormat('sms');
                                }}
                            >
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transSMS',
                                )}
                            </Button>
                            <Button
                                type={
                                    cardFormat === 'ticket'
                                        ? 'primary'
                                        : 'default'
                                }
                                color='primary'
                                className='h-10 w-auto whitespace-nowrap text-sm font-normal'
                                size='sm'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCardFormat('ticket');
                                }}
                            >
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transTicket',
                                )}
                            </Button>
                        </div>
                    </div>
                    <div className='flex justify-between gap-4 h-max flex-col-reverse sm:flex-row'>
                        <div className='sm:w-1/4 w-full h-full'>
                            <p className='text-sm font-semibold mb-0'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transModels',
                                )}
                            </p>
                            <Textarea
                                className='border border-solid border-[#4D4D4D42] rounded-b-[5px] w-full h-[290px] p-2'
                                onChange={(e) => {
                                    setTemplate({
                                        ...template,
                                        props: {
                                            ...template.props,
                                            template_name: e.target.value,
                                        },
                                    });
                                    cardFormat === 'email'
                                        ? setFieldValue(
                                              'ticket_template_email',
                                              template,
                                          )
                                        : cardFormat === 'sms'
                                          ? setFieldValue(
                                                'ticket_template_sms',
                                                template,
                                            )
                                          : setFieldValue(
                                                'ticket_template_paper',
                                                template,
                                            );
                                }}
                            />
                        </div>
                        <div
                            className={`w-full${cardFormat === 'email' ? ' sm:w-1/2' : ' sm:w-1/4'}`}
                        >
                            <p className='text-center text-sm font-semibold mb-0'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transDisplayedText',
                                )}
                            </p>
                            {cardFormat === 'email' ? (
                                <Textarea
                                    className='border border-solid border-[#4D4D4D42] rounded-b-[5px] w-full h-[290px] p-2'
                                    value={values.promotionName}
                                    onChange={(e) => {
                                        setTemplate({
                                            ...template,
                                            data: e.target.value,
                                        });
                                        cardFormat === 'email'
                                            ? setFieldValue(
                                                  'ticket_template_email',
                                                  template,
                                              )
                                            : cardFormat === 'sms'
                                              ? setFieldValue(
                                                    'ticket_template_sms',
                                                    template,
                                                )
                                              : setFieldValue(
                                                    'ticket_template_paper',
                                                    template,
                                                );
                                    }}
                                />
                            ) : (
                                <>
                                    <Textarea
                                        className='border border-solid border-[#4D4D4D42] rounded-b-[5px] w-full h-[290px] p-2'
                                        value={values.promotionName}
                                        onChange={(e) => {
                                            setTemplate({
                                                ...template,
                                                data: e.target.value,
                                            });
                                            cardFormat === 'email'
                                                ? setFieldValue(
                                                      'ticket_template_email',
                                                      template,
                                                  )
                                                : cardFormat === 'sms'
                                                  ? setFieldValue(
                                                        'ticket_template_sms',
                                                        template,
                                                    )
                                                  : setFieldValue(
                                                        'ticket_template_paper',
                                                        template,
                                                    );
                                        }}
                                    />
                                    {cardFormat === 'sms' && (
                                        <p className='italic text-xs text-right'>
                                            {t(
                                                'createPromotionModal.stepperCreatePromotion.transMaxCharacters',
                                            )}
                                        </p>
                                    )}
                                </>
                            )}
                        </div>
                        <div className='sm:w-1/4 w-full h-full'>
                            <p className='text-sm font-semibold mb-0'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transVariables',
                                )}
                            </p>
                            <div className='border border-solid border-[#4D4D4D42] rounded-b-[5px] w-full h-[134px] p-2 overflow-auto cursor-pointer'>
                                {variable_array(t).map(
                                    ({ key, variable }, index) => (
                                        <p
                                            className={`mb-[10px]`}
                                            key={index}
                                            onDoubleClick={() =>
                                                handleDoubleClickVariables(
                                                    variable,
                                                )
                                            }
                                        >
                                            <span className='font-semibold text-sm'>
                                                {key}
                                            </span>{' '}
                                            {'<' + variable + '>'}
                                            {template.props.variables_available?.includes(
                                                variable,
                                            ) && (
                                                <FaCheck
                                                    color='#66A0E2'
                                                    className='ml-2'
                                                    fontSize={14}
                                                />
                                            )}
                                        </p>
                                    ),
                                )}
                            </div>
                            <p className='text-sm font-semibold mb-0'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transFormattingAvailable',
                                )}
                            </p>
                            <div className='border border-solid border-[#4D4D4D42] rounded-b-[5px] w-full h-[134px] p-2 overflow-auto cursor-pointer'>
                                {style_array(t).map(
                                    ({ key, variable }, index) => (
                                        <p
                                            className={`mb-[10px]`}
                                            key={index}
                                            onDoubleClick={() =>
                                                handleDoubleClickStyle(variable)
                                            }
                                        >
                                            <span className='font-semibold text-sm'>
                                                {key}
                                            </span>{' '}
                                            {'<' + variable + '>'}
                                            {template.props.interpretor.commands_available?.includes(
                                                variable,
                                            ) && (
                                                <FaCheck
                                                    color='#66A0E2'
                                                    className='ml-2'
                                                    fontSize={14}
                                                />
                                            )}
                                        </p>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default FourthStepperScreen;
