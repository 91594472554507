import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'react-calendar/dist/Calendar.css';
import { FaCalendarAlt } from 'react-icons/fa';

const DatePicker = ({
    format,
    selectedDate,
    onDateChange,
    label,
    placeholder,
    color = 'primary',
    classNameLabel = '',
    required = false,
    disabled,
    width = ' w-44',
    type,
    minDate,
    error,
}) => {
    // State pour afficher ou masquer le calendrier
    const [showCalendar, setShowCalendar] = useState(false);

    // Ref to keep track of the date picker container
    const datePickerRef = useRef(null);

    // Afficher ou masquer calendar
    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const handleDateChange = (date) => {
        onDateChange(formatSelectedDate(date));
        toggleCalendar(); // Masquer calendar aprés selectionner date
    };

    // Format la date sélectionnée
    const formatSelectedDate = (date) => {
        return date ? moment(date, format).format(format) : '';
    };

    const parsedDate = moment(selectedDate, format);
    const formattedDate = parsedDate.isValid()
        ? parsedDate.format('MM/DD/YYYY')
        : null;

    const colorVariants = {
        primary: 'border-primary-color-600 text-primary-color-600',
        secondary: 'border-secondary-color-600 text-secondary-color-600',
        thirty: 'border-thirty-color-600 text-thirty-color-600',
        neutral: 'border-neutral-color-600 text-neutral-color-600',
    };

    // Fermer calendar si on clique en dehors du sélecteur de date
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                datePickerRef.current &&
                !datePickerRef.current.contains(event.target)
            ) {
                setShowCalendar(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={datePickerRef}
            className={`date-picker relative w-full sm:w-auto${disabled ? ' cursor-not-allowed' : ''}`}
        >
            <div
                className={`text-left font-black text-sm h-[24px]
                    ${colorVariants[color]}
                    ${classNameLabel}
                    `}
            >
                {required && <span className='text-error-color'>*</span>}{' '}
                {label}
            </div>
            <div
                className={`
                    flex flex-row justify-between items-center p-2.5 h-10
                    border border-solid rounded-md cursor-pointer bg-white
                    w-full sm:${width}
                    ${colorVariants[color]}
                    w-full sm:w-auto sm:max-w-[180px]
                    ${error ? 'border-[#cc0000]' : ''}
                `}
                onClick={toggleCalendar}
            >
                <input
                    type='text'
                    value={formatSelectedDate(selectedDate)}
                    disabled={disabled}
                    placeholder={placeholder}
                    className={`!w-full active:!bg-transparent active:!border-none${disabled ? ' cursor-not-allowed' : ''}`}
                    style={{ all: 'unset' }}
                />
                <FaCalendarAlt color={color} fontSize={'16px'} />
            </div>
            {error && error}
            {showCalendar && (
                <div
                    className={`absolute top-16 left-0 w-[250px] shadow-md z-[999] bg-white border border-solid border-gray-300 rounded-md p-2`}
                >
                    <Calendar
                        value={formattedDate}
                        onChange={(date) => handleDateChange(date)}
                        minDate={type === 'endDate' ? minDate : null}
                    />
                </div>
            )}
        </div>
    );
};

DatePicker.defaultProps = {
    placeholder: 'Select a date',
    format: 'DD/MM/YYYY HH:mm',
};

DatePicker.propTypes = {
    format: PropTypes.string,
};

export default DatePicker;
