import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat } from '../../modUtils/functions/dateFormat';
import CKToolTip from '../../modUtils/components/CKToolTip/CKToolTip';
import { ReactComponent as CheckCircleIcon } from '../../modEntity/assets/images/check.svg';
import Toggle from '../../modUtils/components/componentsLibrary/toggle/Toggle';
import ConfirmationModal from '../../modUtils/components/confirmationModal/ConfirmationModal';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/numberFormat';
import DropdownActionsMenu from '../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu';
import {
    Modal,
    Card,
    Spinner,
    Tabs,
    TabPane,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import TablePaginatedScroll from '../../modUtils/components/componentsLibrary/table/TablePaginatedScroll';
import {
    subscription_detail_by_group_get,
    subscription_history_paginated_get,
    subscription_history_detail_update,
    subscription_detail_by_group_update,
    subscription_by_group_paginated_get,
} from '../../modSubscription/slices/subscriptionSlices';
import { transactions_centralized_by_group_paginated_get } from '../../modSupportCentralized/slices/supportCentralizedSlices';
import { eEquipmentType } from 'ck_commun/src/commun_global/enums';
import eServiceType from 'ck_commun/src/commun_global/js/support/e_service_type';
import WalletCentralizedDetail from 'ck_commun/src/app/modWalletCentralized/components/WalletCentralizedDetail';

export default function SubscriptionModal({ entity }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [historyId, setHistoryId] = useState();
    const [visibleMenus, setVisibleMenus] = useState({});
    const [subscription, setSubscription] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const { entityDetail } = useSelector((state) => state.entity);
    const { profilByEntity } = useSelector((state) => state.profil.profil);
    const [subscriptionStatus, setSubscriptionStatus] = useState();
    const [openTab, setOpenTab] = useState();
    const [loadingSubscriptiondetail, setLoadingSubscriptiondetail] =
        useState(false);
    const eSubscriptionHistoryStatus = {
        0: 'notPaid',
        1: 'paid',
        2: 'gift',
        3: 'canceled',
    };
    const [isModalSubscriptionManagement, setIsModalSubscriptionManagement] =
        useState(false);
    const [
        isConfirmModalVisibleAutoRenewal,
        setIsConfirmModalVisibleAutoRenewal,
    ] = useState(false);
    const [
        isConfirmModalVisibleSubscriptionStatus,
        setIsConfirmModalVisibleSubscriptionStatus,
    ] = useState(false);
    const { subscriptionDetail, subscriptions, subscriptionsHistory } =
        useSelector((state) => state.subscription);
    const [checked, setChecked] = useState(subscriptionDetail?.renew_auto);

    const [subscriptionId, setSubscriptionId] = useState(subscriptions[0]?.id); // on récupère l'abonnement actif, il est unique

    const handleVisibleChange = (isVisible, recordId) => {
        setVisibleMenus((prevVisibleMenus) => ({
            ...prevVisibleMenus,
            [recordId]: isVisible,
        }));
    };

    // Fonction pour obtenir la clé à partir de la valeur
    const getKeyByValue = (obj, value) => {
        return Object.keys(obj).find((key) => obj[key] === value);
    };

    const reversedServiceType = Object.fromEntries(
        Object.entries(eServiceType).map(([key, value]) => [
            value,
            parseInt(key),
        ]),
    );

    const columns = [
        {
            title: 'subscriptionModal.startDate',
            dataIndex: 'date_start',
            key: 'date_start',
            render: (text, record) => dateFormat(text),
        },
        {
            title: 'subscriptionModal.endDate',
            dataIndex: 'date_end',
            key: 'date_end',
            render: (text, record) => dateFormat(text),
        },
        {
            title: 'subscriptionModal.price',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => amountFormatWithCurrencySymbol(text),
        },
        {
            title: 'subscriptionModal.status',
            dataIndex: 'status_label',
            key: 'status_label',
        },
        {
            render: (text, record) => {
                const isVisible = visibleMenus[record?.id] || false;

                return (
                    <>
                        {record?.status ===
                        parseInt(
                            getKeyByValue(
                                eSubscriptionHistoryStatus,
                                'notPaid',
                            ),
                        ) ? (
                            <DropdownActionsMenu
                                menuItems={[
                                    <p
                                        onClick={() =>
                                            handleOnClickSubscriptionStatus(
                                                getKeyByValue(
                                                    eSubscriptionHistoryStatus,
                                                    'paid',
                                                ),
                                                record?.id,
                                            )
                                        }
                                    >
                                        {t('subscriptionModal.markedPaid')}
                                    </p>,
                                    <p
                                        onClick={() =>
                                            handleOnClickSubscriptionStatus(
                                                getKeyByValue(
                                                    eSubscriptionHistoryStatus,
                                                    'gift',
                                                ),
                                                record?.id,
                                            )
                                        }
                                    >
                                        {t('subscriptionModal.markedOffered')}
                                    </p>,
                                ]}
                                handleVisibleChange={(isVisible) =>
                                    handleVisibleChange(isVisible, record?.id)
                                }
                                visible={isVisible}
                            />
                        ) : (
                            <CKToolTip
                                placement='topLeft'
                                content={t(
                                    'subscriptionModal.modifySubscriptionHasPaid',
                                )}
                                trigger='click'
                            >
                                <CheckCircleIcon />
                            </CKToolTip>
                        )}
                    </>
                );
            },
        },
    ];

    const columnsHistory = [
        {
            title: t('entityHistory.date'),
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => dateFormat(text),
            width: '25%',
        },
        {
            title: t('entityHistory.salesNumber'),
            dataIndex: 'sale',
            key: 'sale',
            width: '25%',
            render: (text, record) => (
                <CKToolTip
                    placement='topLeft'
                    content={t('entityHistory.salesNumberIsAssociated')}
                    trigger='click'
                >
                    {text}
                </CKToolTip>
            ),
        },
        {
            title: t('entityHistory.site'),
            dataIndex: 'site_name',
            key: 'site_name',
            width: '25%',
        },
        {
            title: t('entityHistory.equipmentType'),
            dataIndex: 'equipment_type',
            key: 'equipment_type',
            render: (text, record) => t(eEquipmentType[text]),
            width: '25%',
        },
        {
            title: t('entityHistory.amount'),
            dataIndex: 'amount_applied',
            key: 'amount_applied',
            render: (text, record) => text + ' €',
            width: '25%',
        },
    ];

    const getSubscription = async () => {
        await dispatch(
            subscription_by_group_paginated_get({
                groupId: groupId,
                isActive: true,
                entityId: entity?.id,
            }),
        ).unwrap();
    };

    const getSubscriptionHistory = async (limit, offset) => {
        const response = await dispatch(
            subscription_history_paginated_get({
                groupId: groupId,
                entityId: entity?.id,
                subscriptionId: subscriptionId,
                ordering: '-date_start',
                limit,
                offset,
            }),
        ).unwrap();
        return response;
    };

    const getTransactionsCentralizedByGroupPaginated_ = async (
        limit,
        offset,
    ) => {
        const response = await dispatch(
            transactions_centralized_by_group_paginated_get({
                groupId: groupId,
                profilId: subscriptionId,
                limit,
                offset,
            }),
        ).unwrap();
        return response;
    };

    const getSubscriptiondetail = async () => {
        setLoadingSubscriptiondetail(true);
        await dispatch(
            subscription_detail_by_group_get({
                groupId: groupId,
                subscriptionId: subscriptions[0]?.id,
            }),
        ).unwrap();
        setLoadingSubscriptiondetail(false);
    };

    const updateSubscriptionStatus = async () => {
        await dispatch(
            subscription_history_detail_update({
                groupId: groupId,
                historyId: historyId,
                status: subscriptionStatus,
            }),
        ).unwrap();
        setSubscription(subscriptionsHistory);
    };

    const updateSubscriptionAutoRenewal = async () => {
        await dispatch(
            subscription_detail_by_group_update({
                groupId: groupId,
                subscriptionId: subscriptionId,
                renewAuto: !subscriptionDetail?.renew_auto,
            }),
        ).unwrap();
    };

    const handleConfirmAutoRenewal = () => {
        setIsConfirmModalVisibleAutoRenewal(false);
        updateSubscriptionAutoRenewal();
    };

    const handleChangeAutoRenewal = () => {
        setIsConfirmModalVisibleAutoRenewal(true);
    };

    const handleConfirmSubscriptionStatus = () => {
        updateSubscriptionStatus();
        setIsConfirmModalVisibleSubscriptionStatus(false);
    };

    const handleOnClickSubscriptionStatus = (status, historyId) => {
        setSubscriptionStatus(status);
        setHistoryId(historyId);
        setIsConfirmModalVisibleSubscriptionStatus(true);
    };

    useEffect(() => {
        getSubscription();
    }, [groupId, entity, profilByEntity]);

    useEffect(() => {
        entityDetail?.profil?.service_type ===
            reversedServiceType['eServiceType.subscription'] &&
            isModalSubscriptionManagement &&
            subscriptions &&
            getSubscriptiondetail();
        setSubscriptionId(subscriptions[0]?.id);
    }, [subscriptions, isModalSubscriptionManagement, subscriptionId]);

    useEffect(() => {
        setChecked(subscriptionDetail?.renew_auto);
    }, [subscriptionDetail?.renew_auto]);

    const onChange = (tabKey) => {
        setOpenTab(tabKey);
    };

    return (
        <>
            {(entityDetail?.profil?.service_type ===
                reversedServiceType['eServiceType.subscription'] ||
                entityDetail?.profil?.service_type ===
                    reversedServiceType['eServiceType.wallet']) && (
                <>
                    <div onClick={() => setIsModalSubscriptionManagement(true)}>
                        <Card
                            height='100%'
                            className='mr-2 min-h-[110px] flex flex-col items-center justify-center relative cursor-pointer'
                        >
                            <div className='text-neutral-color text-sm font-semibold text-center w-64'>
                                {t('subscriptionModal.manageSubscription')}
                            </div>
                        </Card>
                    </div>

                    <Modal
                        title={t('subscriptionModal.manageSubscription')}
                        isOpen={isModalSubscriptionManagement}
                        onClose={() => setIsModalSubscriptionManagement(false)}
                        width={800}
                        height={800}
                    >
                        {loadingSubscriptiondetail ? (
                            <Spinner />
                        ) : (
                            <>
                                {entityDetail?.profil?.service_type ===
                                    reversedServiceType[
                                        'eServiceType.subscription'
                                    ] && (
                                    <>
                                        <div className='w-[50%] mx-auto p-4 flex justify-center rounded-md border border-solid border-neutral-color-100'>
                                            <Toggle
                                                label={t(
                                                    'subscriptionModal.automaticRenewal',
                                                )}
                                                checked={checked}
                                                onChange={
                                                    handleChangeAutoRenewal
                                                }
                                            />
                                        </div>

                                        <ConfirmationModal
                                            onOk={handleConfirmAutoRenewal}
                                            onCancel={() =>
                                                setIsConfirmModalVisibleAutoRenewal(
                                                    false,
                                                )
                                            }
                                            visible={
                                                isConfirmModalVisibleAutoRenewal
                                            }
                                            content={t(
                                                'subscriptionModal.changeYourAutoRenewalStatus',
                                            )}
                                        />
                                    </>
                                )}

                                {entityDetail?.profil?.service_type ===
                                    reversedServiceType[
                                        'eServiceType.wallet'
                                    ] && (
                                    <WalletCentralizedDetail entity={entity} />
                                )}

                                <Tabs
                                    key={openTab}
                                    onChange={onChange}
                                    headerMatchesBodyWidth
                                    defaultActiveKey={null}
                                    openTab={openTab}
                                    style={{
                                        tabs: {
                                            marginTop: '40px',
                                        },
                                    }}
                                >
                                    {entityDetail?.profil?.service_type ===
                                        reversedServiceType[
                                            'eServiceType.subscription'
                                        ] && (
                                        <TabPane
                                            label={t(
                                                'subscriptionModal.paymentHistory',
                                            )}
                                            tabKey='1'
                                        >
                                            <div className='flex justify-center'>
                                                <TablePaginatedScroll
                                                    columns={columns}
                                                    fetch={
                                                        getSubscriptionHistory
                                                    }
                                                    effect={
                                                        subscription?.results
                                                    }
                                                />
                                            </div>
                                        </TabPane>
                                    )}

                                    <TabPane
                                        label={t(
                                            'subscriptionModal.historyOfUses',
                                        )}
                                        tabKey='2'
                                    >
                                        <div className='flex justify-center'>
                                            <TablePaginatedScroll
                                                columns={columnsHistory}
                                                fetch={
                                                    getTransactionsCentralizedByGroupPaginated_
                                                }
                                            />
                                        </div>
                                    </TabPane>
                                </Tabs>

                                <ConfirmationModal
                                    onOk={handleConfirmSubscriptionStatus}
                                    onCancel={() =>
                                        setIsConfirmModalVisibleSubscriptionStatus(
                                            false,
                                        )
                                    }
                                    visible={
                                        isConfirmModalVisibleSubscriptionStatus
                                    }
                                    content={t(
                                        'subscriptionModal.changeTheStatus',
                                    )}
                                />
                            </>
                        )}
                    </Modal>
                </>
            )}
        </>
    );
}
