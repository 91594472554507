import axios from 'axios';
import {
    getAccessToken,
    getRefreshToken,
    refreshAuthToken,
} from '../functions/handleTokens';
import { IS_DEBUG } from '../constants';

// createHttpInstance permet de créer une nouvelle instance d’Axios avec une configuration spécifique
const createHttpInstance = (url, headers) => {
    const instance = axios.create({
        baseURL: url,
        headers: headers,
    });

    // Request interceptor
    instance.interceptors.request.use(
        (config) => {
            // Ajouter le token d'accès aux en-têtes
            const accessToken = getAccessToken();
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
            return config;
        },
        (error) => {
            // Gérer les erreurs de requête
            return Promise.reject(error);
        },
    );

    // Response interceptor
    instance.interceptors.response.use(
        (response) => {
            // Normaliser les données ou gérer les réponses communes
            return response;
        },
        async (error) => {
            // Gérer les erreurs de réponse
            if (error.response) {
                const status = error.response.status;

                switch (status) {
                    case 400:
                        console.error('Bad Request:', error.response.data);
                        break;

                    case 401: {
                        // Gestion de l'erreur 401 : rafraîchir le token
                        const accessToken = getAccessToken();
                        const refreshToken = getRefreshToken();
                        if (accessToken && refreshToken) {
                            try {
                                await refreshAuthToken();
                                // Réappeler la requête après rafraîchissement du token
                                return instance(error.config);
                            } catch (refreshError) {
                                // Gérer les erreurs de rafraîchissement du token
                                return Promise.reject(refreshError);
                            }
                        }
                        break;
                    }

                    case 404:
                        console.error('Not Found:', error.response.data);
                        break;

                    case 500:
                        if (IS_DEBUG) {
                            console.error(
                                'Internal Server Error:',
                                error.response.data,
                            );
                        } else {
                            window.location.href = `${process.env.PUBLIC_URL}/error`;
                        }
                        break;
                    // Add more cases as needed

                    default:
                        console.error(`Unhandled Status Code: ${status}`);
                }
            } else if (error.request) {
                // La requête a été faite mais aucune réponse n’a été reçue
                console.error('Request error:', error.request);
            } else {
                // Une erreur s’est produite lors de la configuration de la requête
                console.error('Error:', error.message);
            }

            // Dispatch an action or handle the error as needed
            // store.dispatch(handleError(error));

            //rethrow the error to propagate it to the component that made the request
            return Promise.reject(error);
        },
    );

    // Configurer l’intervalle de rafraîchissement du token toutes les 15 minutes
    setInterval(refreshAuthToken, 15 * 60 * 1000);

    return instance;
};

export default createHttpInstance;
