import { FaCheck } from "react-icons/fa";

const DayCard = ({ day, selected, selectDay }) => {

  return (
    <div
      className={`specidicDayBloc border-solid border w-12 h-12 flex-display flex-column align-items-center rounded px-2 cursor-pointer${selected ? " selected justify-content-between bg-[#33CF05] border-[#33CF05] text-white" : " justify-content-end text-[#0077FF]"}`}
      onClick={() => selectDay(day)}
    >
        <div>
        {selected && <FaCheck className="mt-1 text-sm" /> }
        </div>
      <span className="mb-1 text-sm	">{day?.substring(0, 3)}</span>
    </div>
  );
};

export default DayCard;
