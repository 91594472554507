import { useState } from 'react';
import DropdownActionsMenu from '../../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu';
import { useTranslation } from 'react-i18next';

const TicketActionsMenu = ({
    toggleModalTestPromo,
    toggleModalSendTicketMail,
    toggleModalCloseTicket,
}) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const hide = () => {
        setVisible(false);
    };

    const toggleModal = () => {
        hide();
        toggleModalTestPromo();
    };

    const handleModalSendTicketMail = () => {
        hide();
        toggleModalSendTicketMail();
    };

    const handleModalCloseTicket = () => {
        hide();
        toggleModalCloseTicket();
    };

    const menuItems = [
        <p className='flex items-center gap-2 text-xs' onClick={toggleModal}>
            {t('modalViewTickets.table.transTestCode')}
        </p>,
        <p
            className='flex items-center gap-2 text-xs'
            onClick={handleModalSendTicketMail}
        >
            {t('modalViewTickets.transSendByMail')}
        </p>,
        <p
            className='flex items-center gap-2 text-xs'
            onClick={handleModalCloseTicket}
        >
            {t('modalViewTickets.transCloseCode')}
        </p>,
        <p className='flex items-center gap-2 text-xs'>
            {t('modalViewTickets.table.transDetails')}
        </p>,
    ];

    const handleVisibleChange = () => {
        setVisible((prevVisible) => !prevVisible);
    };

    return (
        <DropdownActionsMenu
            menuItems={menuItems}
            handleVisibleChange={handleVisibleChange}
            visible={visible}
        />
    );
};

export default TicketActionsMenu;
