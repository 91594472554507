import {
    Input,
    Modal,
    Select,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../../modUtils/components/buttons/submitButton/SubmitButton';
import TicketsTable from '../ticketsTable/TicketsTable';
import FilterByDate from '../../../modUtils/components/filters/FilterByDate';
import moment from 'moment';
import { BsQrCode } from 'react-icons/bs';
import { ReactComponent as Block } from '../../../modUtils/assets/images/block.svg';
import ExportButton from '../../../modUtils/components/buttons/exportButton/ExportButton';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const ModalTickets = ({
    promo,
    modal,
    toggleModalTickets,
    toggleModalGenerateQrCode,
    toggleModalCloseTicketsGroup,
    toggleModalExportTicket,
    toggleModalTestPromo,
    toggleModalSendTicketMail,
    toggleModalCloseTicket,
    setSelectForm,
    selectForm,
    setStartDate,
    setEndDate,
}) => {
    const { t } = useTranslation();
    const { promotions } = useSelector((state) => state.promotion);
    const formatDate = 'DD/MM/YYYY HH:mm';
    const [searchedCode, setSearchedCode] = useState(null);
    const options = [
        {
            value: null,
            label: t('modalViewTickets.filterSelectOptions.transAll'),
        },
        {
            value: 0,
            label: t('modalViewTickets.filterSelectOptions.transInStock'),
        },
        {
            value: 1,
            label: t('modalViewTickets.filterSelectOptions.transDistributed'),
        },
        {
            value: 2,
            label: t('modalViewTickets.filterSelectOptions.transInUse'),
        },
        {
            value: 3,
            label: t('modalViewTickets.filterSelectOptions.transClosed'),
        },
    ];

    function formatedDate(selectedDate) {
        let formatedDate;
        if (typeof selectedDate === 'object') {
            const date = selectedDate;
            formatedDate = moment(date, formatDate, true).format(formatDate);
        } else if (
            typeof selectedDate === 'string' &&
            selectedDate.length > 0
        ) {
            formatedDate = moment(selectedDate, formatDate, true).format(
                formatDate,
            );
        }
        return formatedDate;
    }

    //filtre par date
    const handleDateChange = (selectedDate) => {
        const formatedStartDate = formatedDate(selectedDate[0]);
        const formatedEndDate = formatedDate(selectedDate[1]);

        setStartDate(formatedStartDate);
        setEndDate(formatedEndDate);

        setSelectForm({
            ...selectForm,
            dateStart: formatedStartDate,
            dateEnd: formatedEndDate,
        });
    };

    const submitFilter = () => {
        console.log('selectForm', selectForm);
    };

    const handleSearchCode = (e) => {
        console.log('e', e.target.value);
    };

    return (
        <Modal
            isOpen={modal}
            onClose={toggleModalTickets}
            title={t('modalViewTickets.transTitle')}
            width={'max-content'}
            height={700}
            style={{
                modalTitle: {
                    color: '#25292D',
                    fontSize: '20px',
                    lineHeight: '23px',
                },
            }}
        >
            <div className='flex flex-col sm:flex-row items-end gap-2 mb-4'>
                <Select
                    selectLabel={t('modalViewTickets.transFilterPromotion')}
                    options={[
                        {
                            value: null,
                            label: t('common.transAll'),
                        },
                        ...(promotions ||
                            [].map((promotion) => ({
                                value: promotion.id,
                                label: promotion.name,
                            }))),
                    ]}
                    style={{
                        select: {
                            width: window.innerWidth > 639 ? 'auto' : '100%',
                            minWidth: '220px',
                        },
                        selectOptions: {
                            width: window.innerWidth > 639 ? 'auto' : '100%',
                            minWidth: '220px',
                        },
                    }}
                    defaultActiveFirstOption={true}
                    dropdownMatchSelectWidth={true}
                    onSelect={(e) =>
                        setSelectForm({
                            ...selectForm,
                            promotion: e,
                        })
                    }
                />
                <Select
                    selectLabel={t('modalViewTickets.table.transStatus')}
                    options={options}
                    style={{
                        select: {
                            width: window.innerWidth > 639 ? 'auto' : '100%',
                            minWidth: '180px',
                        },
                        selectOptions: {
                            width: window.innerWidth > 639 ? 'auto' : '100%',
                            minWidth: '180px',
                        },
                    }}
                    onSelect={(e) =>
                        setSelectForm({
                            ...selectForm,
                            status: e,
                        })
                    }
                    defaultActiveFirstOption={true}
                />
                <FilterByDate
                    onValueChange={handleDateChange}
                    defaultPeriod='lastSevenDays'
                />
                <SubmitButton
                    className='w-full sm:w-auto h-10 ml-16 gap-4'
                    onClick={submitFilter}
                />
            </div>
            <div className='flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between gap-4 sm:gap-32 mt-10 mb-2'>
                <Input
                    labelClassName={'text-[#338EF5]'}
                    className={'w-full sm:w-auto'}
                    label={t('modalViewTickets.transSearchQRCodePlaceholder')}
                    placeholder={'123456789'}
                    classnames={'h-10 w-full sm:w-[400px]'}
                    value={searchedCode}
                    onChange={(e) => setSearchedCode(e.target.value)}
                    onPressEnter={(e) => handleSearchCode(e)}
                />
                <div className='flex gap-4 items-center'>
                    <div
                        className={`flex flex-col items-center font-semibold text-[10px]${selectForm.promotion === 'all' ? ' cursor-not-allowed text-[#c9c8c8]' : ' cursor-pointer'}`}
                        onClick={
                            selectForm.promotion === 'all'
                                ? null
                                : toggleModalGenerateQrCode
                        }
                    >
                        <BsQrCode fontSize={30} />
                        {t('modalViewTickets.transGenerateCodes')}
                    </div>
                    <div
                        className='flex flex-col items-center font-semibold text-[10px] cursor-pointer'
                        onClick={toggleModalCloseTicketsGroup}
                    >
                        <Block width={30} height={30} />
                        {t('modalViewTickets.transCloseCode')}
                    </div>
                    <ExportButton onClick={toggleModalExportTicket} />
                </div>
            </div>
            <TicketsTable
                promo={promo}
                toggleModalTestPromo={toggleModalTestPromo}
                toggleModalSendTicketMail={toggleModalSendTicketMail}
                toggleModalCloseTicket={toggleModalCloseTicket}
            />
        </Modal>
    );
};

export default ModalTickets;
