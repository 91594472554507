import { useState } from 'react';
import { Input } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';
import { CgAddR } from 'react-icons/cg';
import { FaRegMinusSquare } from 'react-icons/fa';

const RepeaterTime = ({ startTimeLabel, endTimeLabel, disabled }) => {
    const [repeaterItems, setRepeaterItems] = useState([{ from: '', to: '' }]);

    const addItem = () => {
        setRepeaterItems([{ from: '', to: '' }, ...repeaterItems]);
    };

    const updateItem = (index, name, value) => {
        // Create a copy of the current repeaterItems array
        const updatedItems = [...repeaterItems];

        // Update the specific item at the given index
        updatedItems[index] = { ...updatedItems[index], [name]: value };

        // Set the updated array as the new state
        setRepeaterItems(updatedItems);
    };

    const removeItem = (index) => {
        setRepeaterItems([
            ...repeaterItems.slice(0, index),
            ...repeaterItems.slice(index + 1),
        ]);
    };

    return (
        <>
            {repeaterItems.map((item, index) => (
                <div className={`flex items-end gap-2 mt-2${disabled ? ' cursor-not-allowed	opacity-25' : ''}`} key={index}>
                    <Input
                        type={'time'}
                        label={startTimeLabel}
                        value={item.from}
                        onChange={(e) =>
                            updateItem(index, 'from', e.target.value)
                        }
                    />
                    <Input
                        type={'time'}
                        label={endTimeLabel}
                        value={item.to}
                        onChange={(e) =>
                            updateItem(index, 'to', e.target.value)
                        }
                    />
                    {index === 0 ? (
                        <Button
                            color={'outlined'}
                            className={
                                `mb-[10px] flex items-center justify-center h-10${disabled ? ' cursor-not-allowed	opacity-25' : ''}`
                            }
                            onClick={addItem}
                        >
                            <CgAddR fontSize={20} />
                        </Button>
                    ) : (
                        <Button
                            color={'outlined'}
                            className={
                                `mb-[10px] flex items-center justify-center h-10${disabled ? ' cursor-not-allowed	opacity-25' : ''}`
                            }
                            onClick={() => removeItem(index)}
                        >
                            <FaRegMinusSquare fontSize={20} />
                        </Button>
                    )}
                </div>
            ))}
        </>
    );
};

export default RepeaterTime;
