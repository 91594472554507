import { useTranslation } from 'react-i18next';
import { amountFormatWithCurrencySymbol } from '../../../modUtils/functions/numberFormat';
import { dateFormat } from '../../../modUtils/functions/dateFormat';
import { ePromotionTypes } from '../../assets/js/ennum';
import { useSelector } from 'react-redux';
import { eEquipmentType } from '../../../../commun_global/enums';

const LastStepperScreen = ({ values }) => {
    const { t } = useTranslation();
    const { sites } = useSelector((state) => state.manager);
    const { equipmentTypeGroup } = useSelector((state) => state.equipment);

    return (
        <div className='LastStepperScreen text-center flex-display justify-content-between flex-column align-items-center'>
            <div className='flex-display flex-column align-items-center text-xl leading-9'>
                {t('createPromotionModal.transSuccessCreatePromotionModal')}
                <span className='font-extrabold'>
                    “{values.promotionName || '---'}“
                </span>
            </div>
            <p className='text-lg mt-14'>
                {t('createPromotionModal.transTextShowcard')}
            </p>
            <div className='border border-solid border-[#4D4D4D42] rounded w-full flex flex-col sm:flex-row p-2'>
                <div
                    className={`border-r-0 sm:border-r border-b sm:border-b-0 pb-2 sm:pb-0 border-l-0 border-t-0 border-solid border-[#4D4D4D42] px-3.5 w-full ${values.typePromo ? 'sm:w-1/3' : 'sm:w-1/2'}`}
                >
                    <p className='text-[15px] font-extrabold leading-[20px]'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.showCard.transTitleDesc',
                        )}
                    </p>
                    <p className='mt-4 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.showCard.transPromotionTitle',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {values.promotionName || '---'}
                    </p>
                    <p className='mt-4 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transDescription',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold line-clamp-4'>
                        {values.description || '---'}
                    </p>
                    <p className='mt-4 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.showCard.transTypePromotion',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {t(ePromotionTypes[values.typePromo]) || '---'}
                    </p>
                    <p className='mt-4 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transOfferValidity',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transFrom',
                        )}{' '}
                        {dateFormat(
                            values.startDateOfferObtainPromo,
                            'DD/MM/YYYY',
                        ) || '---'}{' '}
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transTo',
                        )}{' '}
                        {dateFormat(
                            values.endDateOfferObtainPromo,
                            'DD/MM/YYYY',
                        ) || '---'}
                    </p>
                </div>
                {values.typePromo ? (
                    <div className='border-r-0 sm:border-r border-b sm:border-b-0 pb-2 sm:pb-0 border-l-0 border-t-0 border-solid border-[#4D4D4D42] px-3.5 w-full sm:w-1/3'>
                        <p className='text-[15px] font-extrabold leading-[20px]'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.transPromotionPrintingCondition',
                            )}
                        </p>
                        <p className='mt-4 text-left mb-0 text-sm'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.showCard.transNumberOfOfferDelivered',
                            )}
                        </p>
                        <p className='text-left mb-0 text-sm font-semibold'>
                            {values.maximumPrintingObtainPromo || '---'}
                        </p>
                        <p className='mt-4 text-left mb-0 text-sm'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.showCard.transListOfSites',
                            )}
                        </p>
                        <p className='text-left mb-0 text-sm font-semibold'>
                            {values.sitesObtainPromo
                                ? values.sitesObtainPromo
                                      .map((siteId) => {
                                          const site = sites.find(
                                              (site) => site.id === siteId,
                                          );
                                          return site ? site.name : null;
                                      })
                                      .filter((name) => name !== null) // Remove any null values in case a site ID doesn't match
                                      .join(', ')
                                : '---'}
                        </p>
                        <p className='mt-4 text-left mb-0 text-sm'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.showCard.transListOfEquipment',
                            )}
                        </p>
                        <p className='text-left mb-0 text-sm font-semibold'>
                            {values.equipementsTypeObtainPromo
                                ? values.equipementsTypeObtainPromo
                                      .map((equipmentId) => {
                                          const equipment =
                                              equipmentTypeGroup.find(
                                                  (equipment) =>
                                                      equipment.type ===
                                                      equipmentId,
                                              );
                                          return equipment
                                              ? t(
                                                    eEquipmentType[
                                                        equipment.type
                                                    ],
                                                )
                                              : null;
                                      })
                                      .filter((name) => name !== null) // Remove any null values in case a site ID doesn't match
                                      .join(', ')
                                : '---'}
                        </p>
                        <p className='mt-4 text-left mb-0 text-sm'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.showCard.transUseHours',
                            )}
                        </p>
                        <p className='text-left mb-0 text-sm font-semibold'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.transFrom',
                            )}{' '}
                            {dateFormat(
                                values.startDateOfferObtainPromo,
                                'DD/MM/YYYY',
                            ) || '---'}{' '}
                            {t(
                                'createPromotionModal.stepperCreatePromotion.transTo',
                            )}{' '}
                            {dateFormat(
                                values.endDateOfferObtainPromo,
                                'DD/MM/YYYY',
                            ) || '---'}
                        </p>
                    </div>
                ) : null}
                <div className=' px-3.5 w-full sm:w-1/3'>
                    <p className='text-[15px] font-extrabold leading-[20px]'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transConditionUsePromotion',
                        )}
                    </p>
                    <p className='mt-4 text-left mb-0 text-sm'>
                        {t('card.transMinMaxAmount')}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {t('card.transBetween')}{' '}
                        {values.minUsePromo
                            ? amountFormatWithCurrencySymbol(
                                  values.minObtainPromo,
                              )
                            : '---'}{' '}
                        {t('card.transAnd')}{' '}
                        {values.maxUsePromo
                            ? amountFormatWithCurrencySymbol(
                                  values.maxObtainPromo,
                              )
                            : '---'}
                    </p>
                    <p className='mt-4 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transDiscount',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {values.amountUsePromo
                            ? `${values.amountUsePromo} ${values.discountAmount ? '%' : localStorage.getItem('currencySymbol')}`
                            : '---'}
                    </p>
                    <p className='mt-4 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transUniqueUseCode',
                        )}
                    </p>
                    {console.log('values', values)}
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {values.maximumPrintingUsePromo === '1'
                            ? `1 ${t('createPromotionModal.stepperCreatePromotion.transTimes')}`
                            : values.maximumPrintingUsePromo === 'illimited'
                              ? t(
                                    'createPromotionModal.stepperCreatePromotion.transUnlimited',
                                )
                              : `${values.maximumPrintingUsePromo} ${t('createPromotionModal.stepperCreatePromotion.transTimes')}`}
                    </p>
                    <p className='mt-4 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transDailyValidity',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold lowercase'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transStarting',
                        )}{' '}
                        {values.startDayValidityUsePromo || '---'}{' '}
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transUpTo',
                        )}{' '}
                        {values.endDayValidityUsePromo || '---'}
                    </p>
                    <p className='mt-4 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transBuyBetween',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transMinOf',
                        )}{' '}
                        {values.minUsePromo
                            ? amountFormatWithCurrencySymbol(values.minUsePromo)
                            : '---'}{' '}
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transMaxOf',
                        )}{' '}
                        {values.minUsePromo
                            ? amountFormatWithCurrencySymbol(values.minUsePromo)
                            : '---'}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LastStepperScreen;
