import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/dateFormat';
import CKToolTip from 'ck_commun/src/app/modUtils/components/CKToolTip/CKToolTip';
import { Modal } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/numberFormat';
import { ReactComponent as CheckCircleIcon } from 'ck_commun/src/app/modEntity/assets/images/check.svg';
import DropdownActionsMenu from 'ck_commun/src/app/modUtils/components/dropdownActionsMenu/DropdownActionsMenu';
import { subscription_history_paginated_get } from 'ck_commun/src/app/modSubscription/slices/subscriptionSlices';
import TablePaginatedScroll from 'ck_commun/src/app/modUtils/components/componentsLibrary/table/TablePaginatedScroll';

export default function SubscriptionHistorysDetailModal({
    entity,
    isModalVisible,
    setProfilThrough,
    setIsModalVisible,
    entityProfilThrough,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);

    const eSubscriptionHistoryStatus = {
        0: 'notPaid',
        1: 'paid',
        2: 'gift',
        3: 'canceled',
    };

    // Fonction pour obtenir la clé à partir de la valeur
    const getKeyByValue = (obj, value) => {
        return Object.keys(obj).find((key) => obj[key] === value);
    };

    const getSubscriptionHistory = async (limit, offset) => {
        const response = await dispatch(
            subscription_history_paginated_get({
                groupId: groupId,
                entityId: entity?.id,
                subscriptionId: entityProfilThrough.id,
                ordering: '-date_start',
                limit,
                offset,
            }),
        ).unwrap();
        return response;
    };

    const handleOnClickSubscriptionStatus = (status) => {
        setSubscriptionStatus(status);
    };

    const columns = [
        {
            title: 'subscriptionModal.startDate',
            dataIndex: 'date_start',
            key: 'date_start',
            render: (text, record) => dateFormat(text),
        },
        {
            title: 'subscriptionModal.endDate',
            dataIndex: 'date_end',
            key: 'date_end',
            render: (text, record) => dateFormat(text),
        },
        {
            title: 'subscriptionModal.price',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => amountFormatWithCurrencySymbol(text),
        },
        {
            title: 'subscriptionModal.status',
            dataIndex: 'status_label',
            key: 'status_label',
        },
        {
            render: (text, record) => {
                return (
                    <>
                        {record?.status ===
                        parseInt(
                            getKeyByValue(
                                eSubscriptionHistoryStatus,
                                'notPaid',
                            ),
                        ) ? (
                            <DropdownActionsMenu
                                menuItems={[
                                    <p
                                        onClick={() =>
                                            handleOnClickSubscriptionStatus(
                                                getKeyByValue(
                                                    eSubscriptionHistoryStatus,
                                                    'paid',
                                                ),
                                                record?.id,
                                            )
                                        }
                                    >
                                        {t('subscriptionModal.markedPaid')}
                                    </p>,
                                    <p
                                        onClick={() =>
                                            handleOnClickSubscriptionStatus(
                                                getKeyByValue(
                                                    eSubscriptionHistoryStatus,
                                                    'gift',
                                                ),
                                                record?.id,
                                            )
                                        }
                                    >
                                        {t('subscriptionModal.markedOffered')}
                                    </p>,
                                ]}
                            />
                        ) : (
                            <CKToolTip
                                placement='topLeft'
                                content={t(
                                    'subscriptionModal.modifySubscriptionHasPaid',
                                )}
                                trigger='click'
                            >
                                <CheckCircleIcon />
                            </CKToolTip>
                        )}
                    </>
                );
            },
        },
    ];

    const handleOnClose = () => {
        setIsModalVisible(false);
        setProfilThrough(false);
    };

    return (
        <>
            <Modal
                title={t('subscriptionHistorysDetailModal.profileHistory')}
                isOpen={isModalVisible}
                onClose={() => handleOnClose()}
                width={800}
                height={800}
                zIndex={1000}
            >
                <div className='flex flex-row items-center justify-center font-semibold text-sm'>
                    <div className='w-8 mr-2'>
                        <img
                            src={entityProfilThrough.profil?.service_type_image}
                            alt={entityProfilThrough.profil?.service_type_label}
                        />
                    </div>

                    <div>{entityProfilThrough.profil?.name}</div>
                </div>

                <div className='mt-8'>
                    <TablePaginatedScroll
                        fetch={getSubscriptionHistory}
                        columns={columns}
                    />
                </div>
            </Modal>
        </>
    );
}
