import { useTranslation } from 'react-i18next';
import { Modal } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';

const ModalCloseTicket = ({
    toggleModalCloseTicket,
    modalCloseTicket,
    promotion,
    promoTitle,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={modalCloseTicket}
            onClose={toggleModalCloseTicket}
            title={t('modalViewTickets.modalCloseTicket.transTitle')}
        >
            <div className='text-center'>
                <p className='text-sm'>
                    {t(
                        'modalViewTickets.modalCloseTicket.transCloseTicketConfirmMessage',
                    )}
                </p>
                <p className='text-sm font-semibold'>N°: {promotion.code}</p>
                <p className='text-sm font-semibold'>{promoTitle}</p>
                <Button onClick={handleModalSendTicketMail} color={'success'}>
                    {t('modalViewTickets.transCloseCode')}
                </Button>
            </div>
        </Modal>
    );
};

export default ModalCloseTicket;
