import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    httpGet,
    httpPost,
    httpPut,
} from 'ck_commun/src/app/modUtils/apis/httpConfig';

// retourner la liste des configurations
export const get_configuration_by_profil_paginated = createAsyncThunk(
    'mod_profil/get_configuration_by_profil_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/profils/${values.profilId}/configurations/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        name: values.name,
                        ordering: values.ordering,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// créer une configuration
export const create_configuration_by_profil = createAsyncThunk(
    'mod_profil/create_configuration_by_profil',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/profils/${values.profilId}/configurations/`,
                {
                    name: values.name,
                    description: values.description,
                    date_activation: values.dateActivation,
                    profil: values.profilId,
                    price: values.price,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// retourner le détail d'une configuration
export const get_configuration_detail_by_profil = createAsyncThunk(
    'mod_profil/get_configuration_detail_by_profil',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/profils/${values.profilId}/configurations/${values.configurationId}/`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// modifier une configuration
export const update_configuration_detail_by_profil = createAsyncThunk(
    'mod_profil/update_configuration_detail_by_profil',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/profils/${values.profilId}/configurations/${values.configurationId}/`,
                {
                    name: values.name,
                    description: values.description,
                    date_activation: values.dateActivation,
                    constraintidentification_is_active:
                        values.constraintsIdentificationIsActive,
                    constraintfinancial_is_active:
                        values.constraintsFinancialIsActive,
                    constraintperiod_is_active: values.constraintsTimeIsActive,
                    constraintschedule_is_active:
                        values.constraintscheduleIsActive,
                    constraintservice_is_active:
                        values.constraintsServiceIsActive,
                    price: values.price,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Dupliquer une configuration
export const create_configuration_duplicate = createAsyncThunk(
    'mod_profil/create_configuration_duplicate',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/profils/${values.profilId}/configurations/${values.configurationId}/duplicate/`,
                {
                    date_activation: values.dateActivation,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const configurationSlice = createSlice({
    name: 'configuration',
    initialState: {
        loading: true,
        loadingForm: true,
        configurations: [],
        configurationDetail: {},
    },
    reducers: {
        reset_configurations: (state, action) => {
            state.configurations = [];
        },
    },
    extraReducers: (builder) => {
        builder
            /* get_configuration_by_profil_paginated */
            .addCase(
                get_configuration_by_profil_paginated.pending,
                (state, action) => {
                    state.configurations = [];
                },
            )
            .addCase(
                get_configuration_by_profil_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.configurations = action.payload.results;
                },
            )
            .addCase(
                get_configuration_by_profil_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* create_configuration_by_profil*/
            .addCase(
                create_configuration_by_profil.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.configurations = [
                        ...state.configurations,
                        action.payload,
                    ];
                },
            )
            .addCase(
                create_configuration_by_profil.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* get_configuration_detail_by_profil */
            .addCase(
                get_configuration_detail_by_profil.pending,
                (state, action) => {
                    state.loadingForm = true;
                    state.configurationDetail = {};
                },
            )
            .addCase(
                get_configuration_detail_by_profil.fulfilled,
                (state, action) => {
                    state.loadingForm = false;
                    state.configurationDetail = action.payload;
                },
            )
            .addCase(
                get_configuration_detail_by_profil.rejected,
                (state, action) => {
                    state.loadingForm = false;
                },
            )

            /* update_configuration_detail_by_profil */
            .addCase(
                update_configuration_detail_by_profil.fulfilled,
                (state, action) => {
                    state.loading = false;
                    const updatedConfiguration = action.payload;
                    const updatedConfigurationsList = state.configurations.map(
                        (configuration) => {
                            if (configuration.id === updatedConfiguration.id) {
                                return updatedConfiguration;
                            }
                            return configuration;
                        },
                    );
                    state.configurations = updatedConfigurationsList;
                    state.configurationDetail = action.payload;
                },
            )
            .addCase(
                update_configuration_detail_by_profil.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* create_configuration_duplicate */
            .addCase(
                create_configuration_duplicate.fulfilled,
                (state, action) => {
                    state.loading = false;
                    action.payload.index = action.payload.id;
                    state.configurations = [
                        ...state.configurations,
                        action.payload,
                    ];
                },
            )
            .addCase(
                create_configuration_duplicate.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export const { reset_configurations } = configurationSlice.actions;
export default configurationSlice.reducer;
