import { useEffect, useState } from 'react';
import {
    Input,
    Modal,
    Select,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { get_sites_by_group_paginated } from '../../../modManager/slices/managerSlice';
import i18next from 'i18next';
import { eEquipmentType } from '../../../../commun_global/enums';
import { get_types_by_group_paginated } from '../../../modEquipment/slices/equipmentSlice';

const ModalTestPromo = ({ modalTestPromo, toggleModalTestPromo }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId, sites } = useSelector((state) => state.manager);
    const [equipmentTypeGroup, setEquipmentTypeGroup] = useState([]);
    async function fetchData() {
        await dispatch(get_types_by_group_paginated(groupId)).then((result) => {
            var options = result?.payload?.map((equipmentType) => ({
                label: t(eEquipmentType[equipmentType.type]),
                value: equipmentType.type,
            }));
            setEquipmentTypeGroup(options);
        });
    }
    useEffect(() => {
        if (groupId && modalTestPromo) {
            dispatch(
                get_sites_by_group_paginated({
                    limit: 100,
                    offset: 0,
                    groupId: groupId,
                }),
            );
            fetchData();
        }
    }, [groupId, modalTestPromo, i18next.language]);

    const handleSubmit = (values) => {
        console.log('values', values);
        toggleModalTestPromo();
    };
    return (
        <Modal
            isOpen={modalTestPromo}
            onClose={toggleModalTestPromo}
            title={t('modalViewTickets.modalTestPromotion.transTitle')}
            width={700}
            style={{
                modalTitle: {
                    color: '#25292D',
                    fontSize: '20px',
                    lineHeight: '23px',
                },
            }}
        >
            <Formik
                initialValues={{
                    code: 'E9W4UFF9R1FU1M',
                    printedAt: '30/11/2022 16:23:02:24',
                    site: null,
                    equipment: null,
                    price: '18,00',
                    date: '04/12/2024 12:15',
                }}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                {({ values }) => (
                    <div className='w-max m-auto p-6'>
                        <Input
                            label={t('modalViewTickets.table.transCode')}
                            value={values.code}
                            disabled
                        />
                        <Input
                            label={t(
                                'modalViewTickets.modalTestPromotion.transPrintedAt',
                            )}
                            value={values.printedAt}
                        />
                        <div className='flex gap-2 mb-2'>
                            <Select
                                selectLabel={t(
                                    'modalViewTickets.modalTestPromotion.transSite',
                                )}
                                options={[
                                    {
                                        value: 'all',
                                        label: t(
                                            'modalViewTickets.filterSelectOptions.transAll',
                                        ),
                                    },
                                    ...sites?.map((site) => ({
                                        value: site.id,
                                        label: site.name,
                                    })),
                                ]}
                                style={{
                                    select: {
                                        width: '200px',
                                    },
                                    selectLabel: {
                                        color: '#25292D',
                                    },
                                }}
                                defaultActiveFirstOption={true}
                            />
                            <Select
                                selectLabel={t(
                                    'modalViewTickets.modalTestPromotion.transEquipmentType',
                                )}
                                options={[
                                    {
                                        value: 'all',
                                        label: t(
                                            'modalViewTickets.filterSelectOptions.transAll',
                                        ),
                                    },
                                    ...equipmentTypeGroup,
                                ]}
                                style={{
                                    select: {
                                        width: '200px',
                                    },
                                    selectLabel: {
                                        color: '#25292D',
                                    },
                                }}
                                defaultActiveFirstOption={true}
                            />
                        </div>
                        <div className='flex gap-2 mb-2'>
                            <Input
                                label={t(
                                    'modalViewTickets.modalTestPromotion.transPrice',
                                )}
                                value={values.price}
                                className={'w-[200px]'}
                            />
                            <Input
                                label={t(
                                    'modalViewTickets.modalTestPromotion.transTestDate',
                                )}
                                value={values.date}
                                className={'w-[200px]'}
                            />
                        </div>
                        <div className='flex justify-center'>
                            <Button
                                color={'success'}
                                onClick={() => handleSubmit(values)}
                            >
                                {t('modalViewTickets.transTest')}
                            </Button>
                        </div>
                        <div className='flex justify-center items-center border border-solid border-[#4D4D4D42] rounded relative p-2 mt-6'>
                            <span className='absolute top-[-11px] bg-white text-[#25292D] leading-6 text-base px-1'>
                                {t(
                                    'modalViewTickets.modalTestPromotion.transResultTest',
                                )}
                            </span>
                            <div className='testResult'>test</div>
                        </div>
                    </div>
                )}
            </Formik>
        </Modal>
    );
};

export default ModalTestPromo;
