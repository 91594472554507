import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Spinner,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import './ModalCreatePromotion.scss';
import { message, Steps } from 'antd';
import { Formik } from 'formik';
import NavigationButtons from './NavigationButtons';
import { steps } from './steps';
import { StepperScreen } from './StepperScreen';
import { days } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import {
    create_promotion,
    get_promotions_paginated,
    update_promotion,
} from '../../slices/promotionSlice';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { stepValidationSchemas } from './Schema';

const { Step } = Steps;

const ModalCreatePromotion = ({
    groupId,
    setModalCreatePromotion,
    modalCreatePromotionOpen,
    promotionId,
    initialValues,
    setInitialValues,
}) => {
    const dispatch = useDispatch();
    const { loadingDetail } = useSelector((state) => state.promotion);
    const [current, setCurrent] = useState(0);
    const { t } = useTranslation();
    const [last, setLast] = useState(false);

    useEffect(() => {
        if (current > 3) setLast(true);
    }, [current]);

    const onNext = async (validateForm, values) => {
        try {
            const errors = await validateForm();
            if (Object.keys(errors).length === 0) {
                if (current === 0 && !values.typePromo)
                    setCurrent((prev) => prev + 2);
                else {
                    setCurrent((prev) => prev + 1);
                }
            }
        } catch {}
    };

    const prev = (values) => {
        if (current === 2 && !values.typePromo) setCurrent((prev) => prev - 2);
        else setCurrent(current - 1);
    };

    const refreshPromotions = () => {
        dispatch(
            get_promotions_paginated({
                groupId,
                limit: 1000,
                offset: 0,
            }),
        );
    };

    const showSuccess = (success, messageKey) => {
        message.success({
            content: (
                <AlertMessage
                    status={success?.status}
                    alertMessage={t(messageKey)}
                    errorDetail={success?.message}
                />
            ),
        });
    };

    const showError = (error, messageKey) => {
        message.error({
            content: (
                <AlertMessage
                    status={error?.status}
                    alertMessage={t(messageKey)}
                    errorDetail={error?.message}
                />
            ),
        });
    };

    const handleSubmit = async (values) => {
        try {
            const action = promotionId
                ? update_promotion({ groupId, promotionId, ...values })
                : create_promotion({ groupId, ...values });

            const response = await dispatch(action).unwrap();

            if (response) {
                if (!promotionId) toggleModal();
                const successMessageKey = promotionId
                    ? 'success.transSuccessUpdatePromotion'
                    : 'success.transSuccessCreatePromotion';
                showSuccess(response, successMessageKey);
                refreshPromotions();
            }
        } catch (error) {
            const errorMessageKey = promotionId
                ? 'errors.transErrorUpdatePromotion'
                : 'errors.transErrorCreatePromotion';
            showError(error, errorMessageKey);
        }
    };

    const toggleModal = () => {
        setCurrent(0);
        setLast(false);
        setInitialValues({
            promotionName: null,
            description: null,
            typePromo: 1,
            enableObtainPromo: false,
            startDateOfferObtainPromo: null,
            endDateOfferObtainPromo: null,
            maximumPrintingObtainPromo: null,
            sitesObtainPromo: null,
            equipementsTypeObtainPromo: null,
            constraintObtainPromo: [],
            minObtainPromo: null,
            maxObtainPromo: null,
            startDateOfferUsePromo: null,
            endDateOfferUsePromo: null,
            discountAmount: 0,
            amountUsePromo: null,
            maximumPrintingUsePromo: '',
            codeTypeUsepromo: null,
            startDayValidityUsePromo: 0,
            endDayValidityUsePromo: null,
            sitesUsePromo: null,
            equipementsTypeUsePromo: null,
            constraintUsePromo: [],
            minUsePromo: null,
            maxUsePromo: null,
            amountUsePromo: null,
            idsUsePromo: null,
            ticket_template_sms: null,
            ticket_template_email: null,
            ticket_template_paper: null,
            amount: 'amount',
        });
        setModalCreatePromotion(false);
    };

    const modalStyles = {
        modalTitle: {
            color: '#25292D',
            fontSize: '20px',
            lineHeight: '23px',
            marginBottom: '0',
        },
        modalBody: {
            height: '100%',
        },
        modalContent: {
            backgroundColor:
                current === 1 ? '#D6E8FD' : current === 2 ? '#FCF6F0' : '#fff',
        },
    };

    return (
        <Formik
            validationSchema={stepValidationSchemas[current]}
            onSubmit={(values) => handleSubmit(values)}
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                validateForm,
                handleChange,
                handleSubmit,
                setFieldValue,
            }) => (
                <>
                    {modalCreatePromotionOpen && (
                        <Modal
                            isOpen={modalCreatePromotionOpen}
                            onClose={toggleModal}
                            title={
                                current === 0
                                    ? promotionId
                                        ? t('transButtionUpdate')
                                        : t('createPromotionModal.transTitleModal')
                                    : current === 1
                                      ? t(
                                            'createPromotionModal.stepperCreatePromotion.transPromotionPrintingCondition',
                                        )
                                      : current === 2
                                        ? t(
                                              'createPromotionModal.stepperCreatePromotion.transConditionUsePromotion',
                                          )
                                        : current === 3
                                          ? t(
                                                'createPromotionModal.stepperCreatePromotion.transTicketFormat',
                                            )
                                          : current === 4
                                            ? t(
                                                  'createPromotionModal.stepperCreatePromotion.transValidation',
                                              )
                                            : t('createPromotionModal.transTitleModal')
                            }
                            className='modalCreatePromotion'
                            width={'80rem'}
                            height={'50rem'}
                            style={modalStyles}
                        >
                            {loadingDetail ? (
                                <Spinner />
                            ) : (
                                <div className='h-full'>
                                    <form
                                        onSubmit={handleSubmit}
                                        className='flex flex-col justify-content-between h-full'
                                    >
                                        <Steps
                                            current={current}
                                            labelPlacement='vertical'
                                            responsive={false}
                                            className='w-1/2 mx-auto sm:w-1/2 mb-4'
                                        >
                                            {steps(t).map((item, index) => (
                                                <Step
                                                    key={item.title}
                                                    title={item.title}
                                                    icon={
                                                        index === current
                                                            ? item.icon
                                                            : index < current
                                                              ? item.successIcon
                                                              : item.waitingIcon
                                                    }
                                                />
                                            ))}
                                        </Steps>
                                        <StepperScreen
                                            last={last}
                                            days={days(t)}
                                            step={current}
                                            initialValues={initialValues}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                        />
                                        <NavigationButtons
                                            current={current}
                                            steps={steps(t)}
                                            onNext={() =>
                                                onNext(
                                                    validateForm,
                                                    values,
                                                    errors,
                                                )
                                            }
                                            onPrev={() => prev(values)}
                                            handleSubmit={handleSubmit}
                                            values={values}
                                        />
                                    </form>
                                </div>
                            )}
                        </Modal>
                    )}
                </>
            )}
        </Formik>
    );
};

export default ModalCreatePromotion;
