import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import './HeaderPromoPage.scss';
import { Select } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';
import { IoSearch } from 'react-icons/io5';
import { CgAddR } from 'react-icons/cg';

const HeaderPromoPage = ({
    toggleModalTickets,
    toogleCreatePromotionModal,
    setPromotionType,
}) => {
    const { t } = useTranslation();
    const options = [
        { value: null, label: t('common.transAll') },
        {
            value: 0,
            label: t(
                'createPromotionModal.stepperCreatePromotion.transGenerateCodePromo',
            ),
        },
        {
            value: 1,
            label: t(
                'createPromotionModal.stepperCreatePromotion.transAppliMobCodePromo',
            ),
        },
    ];

    const handleSelect = (value) => {
        setPromotionType(value);
    };

    const onChange = (event) => {
        console.log('event', event.target.value);
    };

    return (
        <div className='headerPromoPage'>
            <div className='flex flex-col sm:flex-row items-start sm:items-center gap-3 justify-start sm:justify-end mb-4'>
                <Select
                    className={
                        'flex-display flex-col sm:flex-row gap-2 align-items-start max-w-full w-full sm:w-auto'
                    }
                    options={options}
                    containerClassName='flex flex-row items-center gap-2'
                    defaultActiveFirstOption
                    onSelect={handleSelect}
                    selectLabel={t('transFilter')}
                    width={250}
                    style={{
                        select: {
                            width: '250px',
                        },
                    }}
                />
                <Checkbox
                    className='text-sm'
                    value={'pastPromotion'}
                    onChange={onChange}
                >
                    {t('transShowArchivedPromotions')}
                </Checkbox>
            </div>
            <div className='flex flex-col sm:flex-row items-center gap-2 justify-between'>
                <Button
                    color={'primary'}
                    className={
                        'flex gap-1 items-center font-bold tracking-wide text-sm w-full sm:w-auto'
                    }
                    onClick={toggleModalTickets}
                >
                    <IoSearch />
                    {t('modalViewTickets.transSearchQRCodePlaceholder')}
                </Button>
                <Button
                    type={'rounded'}
                    className={
                        'font-bold shadow-none my-2 flex-display align-items-center gap-2 text-sm w-full sm:w-auto'
                    }
                    color='dark'
                    onClick={() => toogleCreatePromotionModal(null)}
                >
                    <CgAddR /> {t('transButtonCreate')}
                </Button>
            </div>
        </div>
    );
};

export default HeaderPromoPage;
