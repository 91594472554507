import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet } from 'ck_commun/src/app/modUtils/apis/httpConfig';

// retourner la liste de toutes les entités entity_by_group_paginated_get
export const wallet_by_group_paginated_get = createAsyncThunk(
    'mod_wallet_centralized/wallet_by_group_paginated_get',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_wallet_centralized/groups/${values.groupId}/wallets/`,
                {
                    params: {
                        is_active: values.isActive,
                        entity_id: values.entityId,
                        profil_id: values.profilId,
                        ordering: values.ordering,
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// retourne le détail d'un portefeuille PERMISSION(S): Permission(mod_profil:manage_profil)
export const wallet_detail_by_group_get = createAsyncThunk(
    'mod_entity/wallet_detail_by_group_get',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_wallet_centralized/groups/${values.groupId}/wallets/${values.walletId}/`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const walletCentralizedSlices = createSlice({
    name: 'walletCentralized',
    initialState: {
        loading: true,
        walletCentralizedList: [],
        walletCentralizedDetail: {},
        walletCentralizedCount: 0,
    },
    extraReducers: (builder) => {
        builder
            /* wallet_by_group_paginated_get */
            .addCase(wallet_by_group_paginated_get.pending, (state, action) => {
                state.walletCentralizedList = [];
            })
            .addCase(
                wallet_by_group_paginated_get.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.walletCentralizedList = action.payload.results;
                    state.walletCentralizedCount = action.payload.count;
                },
            )
            .addCase(
                wallet_by_group_paginated_get.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* wallet_detail_by_group_get */
            .addCase(wallet_detail_by_group_get.pending, (state, action) => {
                state.walletCentralizedDetail = {};
            })
            .addCase(wallet_detail_by_group_get.fulfilled, (state, action) => {
                state.loading = false;
                state.walletCentralizedDetail = action.payload;
            })
            .addCase(wallet_detail_by_group_get.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export default walletCentralizedSlices.reducer;
